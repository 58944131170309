.box {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background var(--redo-input-duration),
    border var(--redo-input-duration);
  border-radius: var(--redo-xsmall-radius);
}

.check {
  width: 50%;
  height: 50%;
}

.input {
  cursor: inherit;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.input:not(:checked) ~ * .check {
  display: none;
}

.input:checked ~ * .check {
  animation: 0.2s ease check;
}

.input:not(:checked) ~ * .check {
  animation: 0.1s ease uncheck;
  opacity: 0;
}

.label {
  flex: 1;
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-primary-900);
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkbox-button {
  align-items: center;
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  position: relative;
}

.checkbox-button:not(.disabled) {
  cursor: pointer;
}

.checkbox-button.disabled {
  cursor: not-allowed;
}

.checkbox-button.normal:hover input:not(:disabled) ~ .box {
  /* background-color: var(--redo-colors-border-border-secondary); */
}

.checkbox-button.normal:hover .input:not(:disabled):checked ~ .box {
  background-color: var(--redo-colors-background-bg-brand-solid_hover);
}

.checkbox-button.normal .input:checked ~ .box {
  background-color: var(--redo-colors-background-bg-brand-solid);
}

.checkbox-button.normal .input:disabled:checked ~ .box {
  background-color: var(--redo-colors-background-bg-disabled_subtle);
  border-color: var(--redo-colors-border-border-disabled_subtle);
}

.checkbox-button.normal .box {
  border: var(--redo-border-width) solid var(--redo-colors-text-text-disabled);
}

.checkbox-button.normal .check {
  color: white;
}

.checkbox-button.ghost .check {
  color: #222;
}

@keyframes check {
  from {
    opacity: 0;
    scale: 0;
  }

  80% {
    opacity: 1;
    scale: 1.3;
  }
}

@keyframes uncheck {
  from {
    opacity: 1;
  }
}
