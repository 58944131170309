.dropdown-button {
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 200px;
}

.full-width-dropdown-button {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.icon-placeholder {
  display: inline-flex;
}

.icon {
  color: var(--redo-colors-text-text-tertiary-600);
}

.shortcut-container {
  outline: 1px solid var(--redo-colors-border-border-secondary);
}
