.icon-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &.xxs {
    &:not(.outline) {
      width: 16px;
      height: 16px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  &.xs {
    &:not(.outline) {
      width: 24px;
      height: 24px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  &.sm {
    &:not(.outline) {
      width: 32px;
      height: 32px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  &.md {
    &:not(.outline) {
      height: 40px;
      width: 40px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  &.lg {
    &:not(.outline) {
      width: 48px;
      height: 48px;
      min-height: 48px;
      min-width: 48px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }

  &.xl {
    &:not(.outline) {
      width: 56px;
      height: 56px;
      > svg {
        width: 50%;
        height: 50%;
      }
    }
  }
}

.icon.outline {
  &.xxs {
    height: 16px;
    width: 16px;
  }
  &.xs {
    height: 24px;
    width: 24px;
  }
  &.sm {
    height: 32px;
    width: 32px;
  }
  &.md {
    height: 40px;
    width: 40px;
  }
  &.lg {
    height: 48px;
    width: 48px;
  }
  &.xl {
    height: 56px;
    width: 56px;
  }
}
