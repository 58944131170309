.container {
  position: relative;
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.outlet {
  display: flex;
  max-width: 100%;
  flex: 1;
  height: 100%;
}

.outlet:not(:last-child) {
  position: absolute;
  left: 0;
  right: 0;
  max-height: 100%;
}

.outlet-enter {
  opacity: 0;
}

.outlet-enter-active {
  opacity: 1;
  transition: opacity 400ms;
}

.outlet-exit,
.outlet-exit-active {
  overflow: hidden;
  pointer-events: none;
}

.outlet-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
