.redo-button-tab-group {
  display: flex;
  width: 100%;
  background-color: var(--redo-colors-background-bg-secondary_alt);
  border-radius: 8px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  position: relative;
  box-sizing: border-box;
  padding: calc(var(--redo-spacing) * 0.5);

  .tab-buttons-container {
    display: grid;
    width: 100%;
    gap: calc(var(--redo-spacing) * 0.5);
    position: relative;
    z-index: 1;
  }

  &.full-width {
    .tab-buttons-container {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }
  }

  &.xs {
    height: 24px;

    .tab-button {
      font-size: var(--redo-body-xsmall-text-size);
      padding: 0 calc(var(--redo-spacing) * 0.75);
    }
  }

  &.sm {
    height: 28px;

    .tab-button {
      font-size: var(--redo-body-small-text-size);
      padding: 0 var(--redo-spacing);
    }
  }

  &.md {
    height: 36px;

    .tab-button {
      font-size: var(--redo-body-medium-text-size);
      padding: 0 calc(var(--redo-spacing) * 1.5);
    }
  }

  &.lg {
    height: 40px;

    .tab-button {
      font-size: var(--redo-body-medium-text-size);
      padding: 0 calc(var(--redo-spacing) * 2);
    }
  }

  &.xl {
    height: 44px;

    .tab-button {
      font-size: var(--redo-body-medium-text-size);
      padding: 0 calc(var(--redo-spacing) * 2.5);
    }
  }

  .active-indicator {
    position: absolute;
    background-color: var(--redo-colors-background-bg-primary);
    border-radius: 6px;
    z-index: 0;
    box-shadow:
      0 1px 2px 0px var(--redo-shadow-color-primary),
      0 1px 3px 0px var(--redo-shadow-color-primary);
    top: 3px;
    bottom: 3px;
    left: 0;
    width: calc(
      var(--tab-width) - calc(var(--redo-spacing) * 1 / var(--options-length))
    );
    transform: translateX(
      calc(var(--tab-position) + calc(var(--redo-spacing) * 0.5))
    );
    transition:
      transform var(--redo-state-duration) ease,
      width var(--redo-state-duration) ease,
      background-color var(--redo-state-duration) ease;
  }

  .tab-button {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-weight: var(--redo-font-weight-bold);
    color: var(--redo-colors-text-text-disabled);
    cursor: pointer;
    transition:
      color var(--redo-state-duration) ease,
      background-color var(--redo-state-duration) ease;
    border-radius: 6px;
    width: 100%;
    overflow: hidden;

    /* Force all nested elements to respect width constraints */
    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    &.active {
      color: var(--redo-colors-text-text-primary-900);
      background-color: transparent;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    .tab-button {
      cursor: not-allowed;
    }
  }

  /* Theme styles */
  &.themeNormal {
    .tab-button:hover:not(.active):not(:disabled) {
      color: var(--redo-colors-text-text-secondary_hover);
    }
  }

  &.themeDestructive {
    background-color: var(--redo-colors-error-50);
    border-color: var(--redo-colors-error-200);

    .active-indicator {
      background-color: var(--redo-colors-error-200);
    }

    .tab-button:hover:not(.active):not(:disabled) {
      color: var(--redo-colors-error-600);
    }
  }

  &.themeSuccess {
    background-color: var(--redo-colors-success-50);
    border-color: var(--redo-colors-success-200);

    .active-indicator {
      background-color: var(--redo-colors-success-200);
    }

    .tab-button:hover:not(.active):not(:disabled) {
      color: var(--redo-colors-success-600);
    }
  }
}
