.ddefault,
.outline {
  &.brand {
    color: var(--redo-colors-orange-dark-500);
  }
  &.gray {
    color: var(--redo-colors-gray-light-mode-500);
  }
  &.error {
    color: var(--redo-component-colors-utility-error-utility-error-600);
  }
  &.warning {
    color: var(--redo-component-colors-utility-warning-utility-warning-600);
  }
  &.success {
    color: var(--redo-component-colors-utility-success-utility-success-600);
  }
  &.black {
    color: var(--redo-colors-foreground-fg-secondary-700);
  }

  &.ddefault {
    &.brand {
      background-color: var(--redo-colors-gray-light-mode-100);
    }
    &.gray {
      background-color: var(--redo-colors-gray-light-mode-200);
    }
    &.error {
      background-color: var(
        --redo-component-colors-utility-error-utility-error-50
      );
    }
    &.warning {
      background-color: var(
        --redo-component-colors-utility-warning-utility-warning-50
      );
    }
    &.success {
      background-color: var(
        --redo-component-colors-utility-success-utility-success-50
      );
    }
    &.black {
      outline: 2px solid var(--redo-colors-border-border-primary);
      outline-offset: -2px;
    }
  }
}

.high-contrast {
  &.brand {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-component-colors-fg-action-primary);
  }
  &.gray {
    color: var(--redo-colors-gray-light-mode-500);
    background-color: var(--redo-colors-gray-light-mode-200);
  }
  &.success {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-success-600);
  }
  &.error {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-error-600);
  }
  &.warning {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-warning-600);
  }
  &.black {
    color: var(--redo-colors-base-white);
    background-color: var(--redo-colors-background-bg-alternate);
  }
}
