.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs {
  display: flex;
  border-bottom: 2px solid var(--redo-colors-border-border-primary);
  position: relative;
}

.slide {
  bottom: 0;
  border-bottom: 2px solid var(--redo-colors-foreground-fg-primary-900);
  position: absolute;
  transition:
    left 250ms,
    right 250ms;
  margin-bottom: -2px;
}

.tab {
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--redo-colors-text-text-quarterary-500);
  padding: 8px 16px;
  transition: color 250ms;
}

.tab.even-width {
  flex: 1;
}

.tab.large {
  font-size: 18px;
  padding: 14px 16px;
}

.tab.small {
  font-size: 12px;
  padding: 8px 10px;
}

.tab:enabled {
  cursor: pointer;
}

.tab.active {
  color: var(--redo-colors-text-text-primary-900);
}

.tab:enabled:hover {
  /* color: var(--redo-primary-color); */
}

.tab:enabled:active {
  /* color: #412da6; */
}

.outlet {
  padding-top: 20px;
}
