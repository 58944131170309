/* Display */
.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}
.flex-direction-row-reverse {
  flex-direction: row-reverse;
}
.flex-direction-column-reverse {
  flex-direction: column-reverse;
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

/* Position */

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-fixed {
  position: fixed;
}

.position-static {
  position: static;
}

.position-sticky {
  position: sticky;
}

/* Height */

.height-none {
  height: 0;
}

.height-three-quarter-screen {
  height: 75vh;
}

.height-quarter-screen {
  height: 25vh;
}

.height-half-screen {
  height: 50vh;
}

.height-screen {
  height: 100vh;
}

.height-full {
  height: 100%;
}

.height-auto {
  height: auto;
}

.height-fit-content {
  height: fit-content;
}

/* Max Height */

.max-height-none {
  max-height: none;
}

.max-height-three-quarter-screen {
  max-height: 75vh;
}

.max-height-quarter-screen {
  max-height: 25vh;
}

.max-height-half-screen {
  max-height: 50vh;
}

.max-height-screen {
  max-height: 100vh;
}

.max-height-full {
  max-height: 100%;
}

.max-height-auto {
  max-height: auto;
}

.max-height-fit-content {
  max-height: fit-content;
}

/* Overflow */

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-visible {
  overflow-y: visible;
}

.bottom-0 {
  bottom: 0;
}

.bottom-xxs {
  bottom: var(--redo-spacing-xxs);
}

.bottom-xs {
  bottom: var(--redo-spacing-xs);
}

.bottom-sm {
  bottom: var(--redo-spacing-sm);
}

.bottom-md {
  bottom: var(--redo-spacing-md);
}

.bottom-lg {
  bottom: var(--redo-spacing-lg);
}

.bottom-xl {
  bottom: var(--redo-spacing-xl);
}

.bottom-2xl {
  bottom: var(--redo-spacing-2xl);
}

.bottom-3xl {
  bottom: var(--redo-spacing-3xl);
}

.bottom-4xl {
  bottom: var(--redo-spacing-4xl);
}

.bottom-5xl {
  bottom: var(--redo-spacing-5xl);
}

.left-0 {
  left: 0;
}

.left-xxs {
  left: var(--redo-spacing-xxs);
}

.left-xs {
  left: var(--redo-spacing-xs);
}

.left-sm {
  left: var(--redo-spacing-sm);
}

.left-md {
  left: var(--redo-spacing-md);
}

.left-lg {
  left: var(--redo-spacing-lg);
}

.left-xl {
  left: var(--redo-spacing-xl);
}

.left-2xl {
  left: var(--redo-spacing-2xl);
}

.left-3xl {
  left: var(--redo-spacing-3xl);
}

.left-4xl {
  left: var(--redo-spacing-4xl);
}

.left-5xl {
  left: var(--redo-spacing-5xl);
}

.right-0 {
  right: 0;
}

.right-xxs {
  right: var(--redo-spacing-xxs);
}

.right-xs {
  right: var(--redo-spacing-xs);
}

.right-sm {
  right: var(--redo-spacing-sm);
}

.right-md {
  right: var(--redo-spacing-md);
}

.right-lg {
  right: var(--redo-spacing-lg);
}

.right-xl {
  right: var(--redo-spacing-xl);
}

.right-2xl {
  right: var(--redo-spacing-2xl);
}

.right-3xl {
  right: var(--redo-spacing-3xl);
}

.right-4xl {
  right: var(--redo-spacing-4xl);
}

.right-5xl {
  right: var(--redo-spacing-5xl);
}

.top-0 {
  top: 0;
}

.top-xxs {
  top: var(--redo-spacing-xxs);
}

.top-xs {
  top: var(--redo-spacing-xs);
}

.top-sm {
  top: var(--redo-spacing-sm);
}

.top-md {
  top: var(--redo-spacing-md);
}

.top-lg {
  top: var(--redo-spacing-lg);
}

.top-xl {
  top: var(--redo-spacing-xl);
}

.top-2xl {
  top: var(--redo-spacing-2xl);
}

.top-3xl {
  top: var(--redo-spacing-3xl);
}

.top-4xl {
  top: var(--redo-spacing-4xl);
}

.top-5xl {
  top: var(--redo-spacing-5xl);
}
