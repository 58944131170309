.popper {
  z-index: 2147483006 !important; /* 1 higher than RedoDropdown */
}

.subtitle {
  font-size: var(--size-body-xs);
  line-height: 18px;
  color: var(--redo-colors-text-text-quarterary-500);
}

.left-align {
  text-align: left;
}
