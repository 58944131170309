.input-wrapper {
  border-width: 1px;
  border-style: solid;
  border-color: var(--redo-colors-border-border-primary);
  background-color: var(--redo-colors-background-bg-primary);
  &.error {
    border-color: var(--redo-colors-border-border-error);
  }

  &.readonly {
    border-width: 0px;
  }

  &:focus-within {
    border-color: var(--redo-colors-border-border-brand);
    outline: 4px solid var(--redo-primary-extra-light-color);
    outline-width: 4px;
    outline-style: solid;
    outline-color: var(--redo-primary-extra-light-color);
    &.error {
      outline-color: var(
        --redo-component-colors-utility-error-utility-error-100
      );
      border-color: var(--redo-colors-border-border-error);
    }
  }

  &.disabled {
    background-color: var(--redo-colors-background-bg-disabled_subtle);
    border-color: var(--redo-colors-border-border-disabled);
    color: var(--redo-colors-text-text-disabled);
  }

  /*
  * Icon sizes in dev and Figma don't line up exactly,
  *   so I'm just eyeballing a few manual adjustments.
  *   Feel free to tweak if they don't look quite right
  */
  &.small {
    height: 28px;
    & .arrows {
      height: 26px;
      button {
        height: 12px;
        width: 28px;
        svg {
          scale: 1.2;
        }
      }
    }
  }
  &.regular {
    height: 40px;
    padding-left: 14px !important; /* non-arbiter value */
    & .arrows {
      height: 38px;
      button {
        height: 18px;
        width: 36px;
      }
    }
  }

  & svg {
    height: 100%;
    width: 100%;
    display: block;
  }

  & .arrows {
    button {
      cursor: pointer;
      transition: background-color 0.2s;

      svg {
        padding: 1px;
      }

      &:hover,
      &:focus {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-color-button-secondary-color-bg_hover
        );
      }
    }
  }
}

.input {
  &::placeholder {
    color: var(--redo-colors-text-text-placeholder);
  }

  min-width: 0px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
}

.incrementer {
  border-top-right-radius: 4px;
}

.decrementer {
  border-bottom-right-radius: 4px;
}

.incrementer svg {
  margin-top: 1px;
}

.decrementer svg {
  margin-top: -4px;
}

.hidden-text {
  visibility: hidden;
  position: absolute;
}
