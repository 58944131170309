/**
 * Redo styles
 */

@import "~@redotech/style/base.css";
@import "./fonts";
@import "./theme";

body {
  font-family: var(--redo-body-font-family);
  font-size: var(--redo-body-medium-text-size);
  line-height: 1.5;
  min-height: 400px;
  min-width: 320px;
  white-space: pre-wrap;
}

body,
html,
.root {
  display: flex;
  flex-direction: column;
}

body,
.root {
  height: 100%;
  width: 100%;
  flex: 1;
}

html {
  min-height: 100%;
  height: 100%;
}
