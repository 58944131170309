.container {
  display: flex;
  gap: 10px;
}

.container.xs {
  gap: 4px;
}

.container.sm {
  gap: 4px;
}

.container.md {
  gap: 6px;
}

.container.vertical {
  flex-direction: column;
  color: var(--redo-colors-text-text-primary-900);
}

.label {
  /* align-self: flex-start; */
  color: var(--redo-colors-text-text-secondary-700);

  &.xs {
    font-size: var(--redo-body-xsmall-text-size);
  }

  &.sm {
    font-size: var(--redo-body-small-text-size);
  }
}

.container.light .label {
  font-size: var(--redo-body-medium-text-size);
  font-weight: normal;
}

.container.thin-bold .label {
  font-weight: 500;
}

.container.thin .label {
  font-size: var(--redo-body-small-text-size);
  font-weight: normal;
}

.error {
  color: var(--redo-colors-text-text-error-primary-600);
}

/* Had to add this because the LabelTheme prop is messing with
font sizes for some reason */
.container.thin-bold .label.xs {
  font-size: var(--redo-body-xsmall-text-size);
}
