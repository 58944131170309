:root,
:host {
  --redo-spacing: 8px; /* DEPRECATED */
  --redo-spacing-unit: 4px;
  --redo-spacing-none: calc(var(--redo-spacing-unit) * 0);
  --redo-spacing-xxs: calc(var(--redo-spacing-unit) * 0.5);
  --redo-spacing-xs: calc(var(--redo-spacing-unit) * 1);
  --redo-spacing-sm: calc(var(--redo-spacing-unit) * 1.5);
  --redo-spacing-md: calc(var(--redo-spacing-unit) * 2);
  --redo-spacing-lg: calc(var(--redo-spacing-unit) * 3);
  --redo-spacing-xl: calc(var(--redo-spacing-unit) * 4);
  --redo-spacing-2xl: calc(var(--redo-spacing-unit) * 5);
  --redo-spacing-3xl: calc(var(--redo-spacing-unit) * 6);
  --redo-spacing-4xl: calc(var(--redo-spacing-unit) * 8);
  --redo-spacing-5xl: calc(var(--redo-spacing-unit) * 10);
  --redo-spacing-6xl: calc(var(--redo-spacing-unit) * 12);
  --redo-spacing-7xl: calc(var(--redo-spacing-unit) * 16);
  --redo-spacing-8xl: calc(var(--redo-spacing-unit) * 20);
  --redo-spacing-9xl: calc(var(--redo-spacing-unit) * 24);
  --redo-spacing-10xl: calc(var(--redo-spacing-unit) * 32);
  --redo-spacing-11xl: calc(var(--redo-spacing-unit) * 40);
}

.mt-none {
  margin-top: var(--redo-spacing-none);
}

.mr-none {
  margin-right: var(--redo-spacing-none);
}

.mb-none {
  margin-bottom: var(--redo-spacing-none);
}

.ml-none {
  margin-left: var(--redo-spacing-none);
}

.m-none {
  margin: var(--redo-spacing-none);
}

.mx-none {
  margin-left: var(--redo-spacing-none);
  margin-right: var(--redo-spacing-none);
}

.my-none {
  margin-top: var(--redo-spacing-none);
  margin-bottom: var(--redo-spacing-none);
}

.pt-none {
  padding-top: var(--redo-spacing-none);
}

.pr-none {
  padding-right: var(--redo-spacing-none);
}

.pb-none {
  padding-bottom: var(--redo-spacing-none);
}

.pl-none {
  padding-left: var(--redo-spacing-none);
}

.p-none {
  padding: var(--redo-spacing-none);
}

.px-none {
  padding-left: var(--redo-spacing-none);
  padding-right: var(--redo-spacing-none);
}

.py-none {
  padding-top: var(--redo-spacing-none);
  padding-bottom: var(--redo-spacing-none);
}

.gap-none {
  gap: var(--redo-spacing-none);
}

.row-gap-none {
  row-gap: var(--redo-spacing-none);
}

.column-gap-none {
  column-gap: var(--redo-spacing-none);
}

.mt-xxs {
  margin-top: var(--redo-spacing-xxs);
}

.mr-xxs {
  margin-right: var(--redo-spacing-xxs);
}

.mb-xxs {
  margin-bottom: var(--redo-spacing-xxs);
}

.ml-xxs {
  margin-left: var(--redo-spacing-xxs);
}

.m-xxs {
  margin: var(--redo-spacing-xxs);
}

.mx-xxs {
  margin-left: var(--redo-spacing-xxs);
  margin-right: var(--redo-spacing-xxs);
}

.my-xxs {
  margin-top: var(--redo-spacing-xxs);
  margin-bottom: var(--redo-spacing-xxs);
}

.pt-xxs {
  padding-top: var(--redo-spacing-xxs);
}

.pr-xxs {
  padding-right: var(--redo-spacing-xxs);
}

.pb-xxs {
  padding-bottom: var(--redo-spacing-xxs);
}

.pl-xxs {
  padding-left: var(--redo-spacing-xxs);
}

.p-xxs {
  padding: var(--redo-spacing-xxs);
}

.px-xxs {
  padding-left: var(--redo-spacing-xxs);
  padding-right: var(--redo-spacing-xxs);
}

.py-xxs {
  padding-top: var(--redo-spacing-xxs);
  padding-bottom: var(--redo-spacing-xxs);
}

.gap-xxs {
  gap: var(--redo-spacing-xxs);
}

.row-gap-xxs {
  row-gap: var(--redo-spacing-xxs);
}

.column-gap-xxs {
  column-gap: var(--redo-spacing-xxs);
}

.mt-xs {
  margin-top: var(--redo-spacing-xs);
}

.mr-xs {
  margin-right: var(--redo-spacing-xs);
}

.mb-xs {
  margin-bottom: var(--redo-spacing-xs);
}

.ml-xs {
  margin-left: var(--redo-spacing-xs);
}

.m-xs {
  margin: var(--redo-spacing-xs);
}

.mx-xs {
  margin-left: var(--redo-spacing-xs);
  margin-right: var(--redo-spacing-xs);
}

.my-xs {
  margin-top: var(--redo-spacing-xs);
  margin-bottom: var(--redo-spacing-xs);
}

.pt-xs {
  padding-top: var(--redo-spacing-xs);
}

.pr-xs {
  padding-right: var(--redo-spacing-xs);
}

.pb-xs {
  padding-bottom: var(--redo-spacing-xs);
}

.pl-xs {
  padding-left: var(--redo-spacing-xs);
}

.p-xs {
  padding: var(--redo-spacing-xs);
}

.px-xs {
  padding-left: var(--redo-spacing-xs);
  padding-right: var(--redo-spacing-xs);
}

.py-xs {
  padding-top: var(--redo-spacing-xs);
  padding-bottom: var(--redo-spacing-xs);
}

.gap-xs {
  gap: var(--redo-spacing-xs);
}

.row-gap-xs {
  row-gap: var(--redo-spacing-xs);
}

.column-gap-xs {
  column-gap: var(--redo-spacing-xs);
}

.mt-sm {
  margin-top: var(--redo-spacing-sm);
}

.mr-sm {
  margin-right: var(--redo-spacing-sm);
}

.mb-sm {
  margin-bottom: var(--redo-spacing-sm);
}

.ml-sm {
  margin-left: var(--redo-spacing-sm);
}

.m-sm {
  margin: var(--redo-spacing-sm);
}

.mx-sm {
  margin-left: var(--redo-spacing-sm);
  margin-right: var(--redo-spacing-sm);
}

.my-sm {
  margin-top: var(--redo-spacing-sm);
  margin-bottom: var(--redo-spacing-sm);
}

.pt-sm {
  padding-top: var(--redo-spacing-sm);
}

.pr-sm {
  padding-right: var(--redo-spacing-sm);
}

.pb-sm {
  padding-bottom: var(--redo-spacing-sm);
}

.pl-sm {
  padding-left: var(--redo-spacing-sm);
}

.p-sm {
  padding: var(--redo-spacing-sm);
}

.px-sm {
  padding-left: var(--redo-spacing-sm);
  padding-right: var(--redo-spacing-sm);
}

.py-sm {
  padding-top: var(--redo-spacing-sm);
  padding-bottom: var(--redo-spacing-sm);
}

.gap-sm {
  gap: var(--redo-spacing-sm);
}

.row-gap-sm {
  row-gap: var(--redo-spacing-sm);
}

.column-gap-sm {
  column-gap: var(--redo-spacing-sm);
}

.mt-md {
  margin-top: var(--redo-spacing-md);
}

.mr-md {
  margin-right: var(--redo-spacing-md);
}

.mb-md {
  margin-bottom: var(--redo-spacing-md);
}

.ml-md {
  margin-left: var(--redo-spacing-md);
}

.m-md {
  margin: var(--redo-spacing-md);
}

.mx-md {
  margin-left: var(--redo-spacing-md);
  margin-right: var(--redo-spacing-md);
}

.my-md {
  margin-top: var(--redo-spacing-md);
  margin-bottom: var(--redo-spacing-md);
}

.pt-md {
  padding-top: var(--redo-spacing-md);
}

.pr-md {
  padding-right: var(--redo-spacing-md);
}

.pb-md {
  padding-bottom: var(--redo-spacing-md);
}

.pl-md {
  padding-left: var(--redo-spacing-md);
}

.p-md {
  padding: var(--redo-spacing-md);
}

.px-md {
  padding-left: var(--redo-spacing-md);
  padding-right: var(--redo-spacing-md);
}

.py-md {
  padding-top: var(--redo-spacing-md);
  padding-bottom: var(--redo-spacing-md);
}

.gap-md {
  gap: var(--redo-spacing-md);
}

.row-gap-md {
  row-gap: var(--redo-spacing-md);
}

.column-gap-md {
  column-gap: var(--redo-spacing-md);
}

.mt-lg {
  margin-top: var(--redo-spacing-lg);
}

.mr-lg {
  margin-right: var(--redo-spacing-lg);
}

.mb-lg {
  margin-bottom: var(--redo-spacing-lg);
}

.ml-lg {
  margin-left: var(--redo-spacing-lg);
}

.m-lg {
  margin: var(--redo-spacing-lg);
}

.mx-lg {
  margin-left: var(--redo-spacing-lg);
  margin-right: var(--redo-spacing-lg);
}

.my-lg {
  margin-top: var(--redo-spacing-lg);
  margin-bottom: var(--redo-spacing-lg);
}

.pt-lg {
  padding-top: var(--redo-spacing-lg);
}

.pr-lg {
  padding-right: var(--redo-spacing-lg);
}

.pb-lg {
  padding-bottom: var(--redo-spacing-lg);
}

.pl-lg {
  padding-left: var(--redo-spacing-lg);
}

.p-lg {
  padding: var(--redo-spacing-lg);
}

.px-lg {
  padding-left: var(--redo-spacing-lg);
  padding-right: var(--redo-spacing-lg);
}

.py-lg {
  padding-top: var(--redo-spacing-lg);
  padding-bottom: var(--redo-spacing-lg);
}

.gap-lg {
  gap: var(--redo-spacing-lg);
}

.row-gap-lg {
  row-gap: var(--redo-spacing-lg);
}

.column-gap-lg {
  column-gap: var(--redo-spacing-lg);
}

.mt-xl {
  margin-top: var(--redo-spacing-xl);
}

.mr-xl {
  margin-right: var(--redo-spacing-xl);
}

.mb-xl {
  margin-bottom: var(--redo-spacing-xl);
}

.ml-xl {
  margin-left: var(--redo-spacing-xl);
}

.m-xl {
  margin: var(--redo-spacing-xl);
}

.mx-xl {
  margin-left: var(--redo-spacing-xl);
  margin-right: var(--redo-spacing-xl);
}

.my-xl {
  margin-top: var(--redo-spacing-xl);
  margin-bottom: var(--redo-spacing-xl);
}

.pt-xl {
  padding-top: var(--redo-spacing-xl);
}

.pr-xl {
  padding-right: var(--redo-spacing-xl);
}

.pb-xl {
  padding-bottom: var(--redo-spacing-xl);
}

.pl-xl {
  padding-left: var(--redo-spacing-xl);
}

.p-xl {
  padding: var(--redo-spacing-xl);
}

.px-xl {
  padding-left: var(--redo-spacing-xl);
  padding-right: var(--redo-spacing-xl);
}

.py-xl {
  padding-top: var(--redo-spacing-xl);
  padding-bottom: var(--redo-spacing-xl);
}

.gap-xl {
  gap: var(--redo-spacing-xl);
}

.row-gap-xl {
  row-gap: var(--redo-spacing-xl);
}

.column-gap-xl {
  column-gap: var(--redo-spacing-xl);
}

.mt-2xl {
  margin-top: var(--redo-spacing-2xl);
}

.mr-2xl {
  margin-right: var(--redo-spacing-2xl);
}

.mb-2xl {
  margin-bottom: var(--redo-spacing-2xl);
}

.ml-2xl {
  margin-left: var(--redo-spacing-2xl);
}

.m-2xl {
  margin: var(--redo-spacing-2xl);
}

.mx-2xl {
  margin-left: var(--redo-spacing-2xl);
  margin-right: var(--redo-spacing-2xl);
}

.my-2xl {
  margin-top: var(--redo-spacing-2xl);
  margin-bottom: var(--redo-spacing-2xl);
}

.pt-2xl {
  padding-top: var(--redo-spacing-2xl);
}

.pr-2xl {
  padding-right: var(--redo-spacing-2xl);
}

.pb-2xl {
  padding-bottom: var(--redo-spacing-2xl);
}

.pl-2xl {
  padding-left: var(--redo-spacing-2xl);
}

.p-2xl {
  padding: var(--redo-spacing-2xl);
}

.px-2xl {
  padding-left: var(--redo-spacing-2xl);
  padding-right: var(--redo-spacing-2xl);
}

.py-2xl {
  padding-top: var(--redo-spacing-2xl);
  padding-bottom: var(--redo-spacing-2xl);
}

.gap-2xl {
  gap: var(--redo-spacing-2xl);
}

.row-gap-2xl {
  row-gap: var(--redo-spacing-2xl);
}

.column-gap-2xl {
  column-gap: var(--redo-spacing-2xl);
}

.mt-3xl {
  margin-top: var(--redo-spacing-3xl);
}

.mr-3xl {
  margin-right: var(--redo-spacing-3xl);
}

.mb-3xl {
  margin-bottom: var(--redo-spacing-3xl);
}

.ml-3xl {
  margin-left: var(--redo-spacing-3xl);
}

.m-3xl {
  margin: var(--redo-spacing-3xl);
}

.mx-3xl {
  margin-left: var(--redo-spacing-3xl);
  margin-right: var(--redo-spacing-3xl);
}

.my-3xl {
  margin-top: var(--redo-spacing-3xl);
  margin-bottom: var(--redo-spacing-3xl);
}

.pt-3xl {
  padding-top: var(--redo-spacing-3xl);
}

.pr-3xl {
  padding-right: var(--redo-spacing-3xl);
}

.pb-3xl {
  padding-bottom: var(--redo-spacing-3xl);
}

.pl-3xl {
  padding-left: var(--redo-spacing-3xl);
}

.p-3xl {
  padding: var(--redo-spacing-3xl);
}

.px-3xl {
  padding-left: var(--redo-spacing-3xl);
  padding-right: var(--redo-spacing-3xl);
}

.py-3xl {
  padding-top: var(--redo-spacing-3xl);
  padding-bottom: var(--redo-spacing-3xl);
}

.gap-3xl {
  gap: var(--redo-spacing-3xl);
}

.row-gap-3xl {
  row-gap: var(--redo-spacing-3xl);
}

.column-gap-3xl {
  column-gap: var(--redo-spacing-3xl);
}

.mt-4xl {
  margin-top: var(--redo-spacing-4xl);
}

.mr-4xl {
  margin-right: var(--redo-spacing-4xl);
}

.mb-4xl {
  margin-bottom: var(--redo-spacing-4xl);
}

.ml-4xl {
  margin-left: var(--redo-spacing-4xl);
}

.m-4xl {
  margin: var(--redo-spacing-4xl);
}

.mx-4xl {
  margin-left: var(--redo-spacing-4xl);
  margin-right: var(--redo-spacing-4xl);
}

.my-4xl {
  margin-top: var(--redo-spacing-4xl);
  margin-bottom: var(--redo-spacing-4xl);
}

.pt-4xl {
  padding-top: var(--redo-spacing-4xl);
}

.pr-4xl {
  padding-right: var(--redo-spacing-4xl);
}

.pb-4xl {
  padding-bottom: var(--redo-spacing-4xl);
}

.pl-4xl {
  padding-left: var(--redo-spacing-4xl);
}

.p-4xl {
  padding: var(--redo-spacing-4xl);
}

.px-4xl {
  padding-left: var(--redo-spacing-4xl);
  padding-right: var(--redo-spacing-4xl);
}

.py-4xl {
  padding-top: var(--redo-spacing-4xl);
  padding-bottom: var(--redo-spacing-4xl);
}

.gap-4xl {
  gap: var(--redo-spacing-4xl);
}

.row-gap-4xl {
  row-gap: var(--redo-spacing-4xl);
}

.column-gap-4xl {
  column-gap: var(--redo-spacing-4xl);
}

.mt-5xl {
  margin-top: var(--redo-spacing-5xl);
}

.mr-5xl {
  margin-right: var(--redo-spacing-5xl);
}

.mb-5xl {
  margin-bottom: var(--redo-spacing-5xl);
}

.ml-5xl {
  margin-left: var(--redo-spacing-5xl);
}

.m-5xl {
  margin: var(--redo-spacing-5xl);
}

.mx-5xl {
  margin-left: var(--redo-spacing-5xl);
  margin-right: var(--redo-spacing-5xl);
}

.my-5xl {
  margin-top: var(--redo-spacing-5xl);
  margin-bottom: var(--redo-spacing-5xl);
}

.pt-5xl {
  padding-top: var(--redo-spacing-5xl);
}

.pr-5xl {
  padding-right: var(--redo-spacing-5xl);
}

.pb-5xl {
  padding-bottom: var(--redo-spacing-5xl);
}

.pl-5xl {
  padding-left: var(--redo-spacing-5xl);
}

.p-5xl {
  padding: var(--redo-spacing-5xl);
}

.px-5xl {
  padding-left: var(--redo-spacing-5xl);
  padding-right: var(--redo-spacing-5xl);
}

.py-5xl {
  padding-top: var(--redo-spacing-5xl);
  padding-bottom: var(--redo-spacing-5xl);
}

.gap-5xl {
  gap: var(--redo-spacing-5xl);
}

.row-gap-5xl {
  row-gap: var(--redo-spacing-5xl);
}

.column-gap-5xl {
  column-gap: var(--redo-spacing-5xl);
}

.mt-6xl {
  margin-top: var(--redo-spacing-6xl);
}

.mr-6xl {
  margin-right: var(--redo-spacing-6xl);
}

.mb-6xl {
  margin-bottom: var(--redo-spacing-6xl);
}

.ml-6xl {
  margin-left: var(--redo-spacing-6xl);
}

.m-6xl {
  margin: var(--redo-spacing-6xl);
}

.mx-6xl {
  margin-left: var(--redo-spacing-6xl);
  margin-right: var(--redo-spacing-6xl);
}

.my-6xl {
  margin-top: var(--redo-spacing-6xl);
  margin-bottom: var(--redo-spacing-6xl);
}

.pt-6xl {
  padding-top: var(--redo-spacing-6xl);
}

.pr-6xl {
  padding-right: var(--redo-spacing-6xl);
}

.pb-6xl {
  padding-bottom: var(--redo-spacing-6xl);
}

.pl-6xl {
  padding-left: var(--redo-spacing-6xl);
}

.p-6xl {
  padding: var(--redo-spacing-6xl);
}

.px-6xl {
  padding-left: var(--redo-spacing-6xl);
  padding-right: var(--redo-spacing-6xl);
}

.py-6xl {
  padding-top: var(--redo-spacing-6xl);
  padding-bottom: var(--redo-spacing-6xl);
}

.gap-6xl {
  gap: var(--redo-spacing-6xl);
}

.row-gap-6xl {
  row-gap: var(--redo-spacing-6xl);
}

.column-gap-6xl {
  column-gap: var(--redo-spacing-6xl);
}

.mt-7xl {
  margin-top: var(--redo-spacing-7xl);
}

.mr-7xl {
  margin-right: var(--redo-spacing-7xl);
}

.mb-7xl {
  margin-bottom: var(--redo-spacing-7xl);
}

.ml-7xl {
  margin-left: var(--redo-spacing-7xl);
}

.m-7xl {
  margin: var(--redo-spacing-7xl);
}

.mx-7xl {
  margin-left: var(--redo-spacing-7xl);
  margin-right: var(--redo-spacing-7xl);
}

.my-7xl {
  margin-top: var(--redo-spacing-7xl);
  margin-bottom: var(--redo-spacing-7xl);
}

.pt-7xl {
  padding-top: var(--redo-spacing-7xl);
}

.pr-7xl {
  padding-right: var(--redo-spacing-7xl);
}

.pb-7xl {
  padding-bottom: var(--redo-spacing-7xl);
}

.pl-7xl {
  padding-left: var(--redo-spacing-7xl);
}

.p-7xl {
  padding: var(--redo-spacing-7xl);
}

.px-7xl {
  padding-left: var(--redo-spacing-7xl);
  padding-right: var(--redo-spacing-7xl);
}

.py-7xl {
  padding-top: var(--redo-spacing-7xl);
  padding-bottom: var(--redo-spacing-7xl);
}

.gap-7xl {
  gap: var(--redo-spacing-7xl);
}

.row-gap-7xl {
  row-gap: var(--redo-spacing-7xl);
}

.column-gap-7xl {
  column-gap: var(--redo-spacing-7xl);
}

.mt-8xl {
  margin-top: var(--redo-spacing-8xl);
}

.mr-8xl {
  margin-right: var(--redo-spacing-8xl);
}

.mb-8xl {
  margin-bottom: var(--redo-spacing-8xl);
}

.ml-8xl {
  margin-left: var(--redo-spacing-8xl);
}

.m-8xl {
  margin: var(--redo-spacing-8xl);
}

.mx-8xl {
  margin-left: var(--redo-spacing-8xl);
  margin-right: var(--redo-spacing-8xl);
}

.my-8xl {
  margin-top: var(--redo-spacing-8xl);
  margin-bottom: var(--redo-spacing-8xl);
}

.pt-8xl {
  padding-top: var(--redo-spacing-8xl);
}

.pr-8xl {
  padding-right: var(--redo-spacing-8xl);
}

.pb-8xl {
  padding-bottom: var(--redo-spacing-8xl);
}

.pl-8xl {
  padding-left: var(--redo-spacing-8xl);
}

.p-8xl {
  padding: var(--redo-spacing-8xl);
}

.px-8xl {
  padding-left: var(--redo-spacing-8xl);
  padding-right: var(--redo-spacing-8xl);
}

.py-8xl {
  padding-top: var(--redo-spacing-8xl);
  padding-bottom: var(--redo-spacing-8xl);
}

.gap-8xl {
  gap: var(--redo-spacing-8xl);
}

.row-gap-8xl {
  row-gap: var(--redo-spacing-8xl);
}

.column-gap-8xl {
  column-gap: var(--redo-spacing-8xl);
}

.mt-9xl {
  margin-top: var(--redo-spacing-9xl);
}

.mr-9xl {
  margin-right: var(--redo-spacing-9xl);
}

.mb-9xl {
  margin-bottom: var(--redo-spacing-9xl);
}

.ml-9xl {
  margin-left: var(--redo-spacing-9xl);
}

.m-9xl {
  margin: var(--redo-spacing-9xl);
}

.mx-9xl {
  margin-left: var(--redo-spacing-9xl);
  margin-right: var(--redo-spacing-9xl);
}

.my-9xl {
  margin-top: var(--redo-spacing-9xl);
  margin-bottom: var(--redo-spacing-9xl);
}

.pt-9xl {
  padding-top: var(--redo-spacing-9xl);
}

.pr-9xl {
  padding-right: var(--redo-spacing-9xl);
}

.pb-9xl {
  padding-bottom: var(--redo-spacing-9xl);
}

.pl-9xl {
  padding-left: var(--redo-spacing-9xl);
}

.p-9xl {
  padding: var(--redo-spacing-9xl);
}

.px-9xl {
  padding-left: var(--redo-spacing-9xl);
  padding-right: var(--redo-spacing-9xl);
}

.py-9xl {
  padding-top: var(--redo-spacing-9xl);
  padding-bottom: var(--redo-spacing-9xl);
}

.gap-9xl {
  gap: var(--redo-spacing-9xl);
}

.row-gap-9xl {
  row-gap: var(--redo-spacing-9xl);
}

.column-gap-9xl {
  column-gap: var(--redo-spacing-9xl);
}

.mt-10xl {
  margin-top: var(--redo-spacing-10xl);
}

.mr-10xl {
  margin-right: var(--redo-spacing-10xl);
}

.mb-10xl {
  margin-bottom: var(--redo-spacing-10xl);
}

.ml-10xl {
  margin-left: var(--redo-spacing-10xl);
}

.m-10xl {
  margin: var(--redo-spacing-10xl);
}

.mx-10xl {
  margin-left: var(--redo-spacing-10xl);
  margin-right: var(--redo-spacing-10xl);
}

.my-10xl {
  margin-top: var(--redo-spacing-10xl);
  margin-bottom: var(--redo-spacing-10xl);
}

.pt-10xl {
  padding-top: var(--redo-spacing-10xl);
}

.pr-10xl {
  padding-right: var(--redo-spacing-10xl);
}

.pb-10xl {
  padding-bottom: var(--redo-spacing-10xl);
}

.pl-10xl {
  padding-left: var(--redo-spacing-10xl);
}

.p-10xl {
  padding: var(--redo-spacing-10xl);
}

.px-10xl {
  padding-left: var(--redo-spacing-10xl);
  padding-right: var(--redo-spacing-10xl);
}

.py-10xl {
  padding-top: var(--redo-spacing-10xl);
  padding-bottom: var(--redo-spacing-10xl);
}

.gap-10xl {
  gap: var(--redo-spacing-10xl);
}

.row-gap-10xl {
  row-gap: var(--redo-spacing-10xl);
}

.column-gap-10xl {
  column-gap: var(--redo-spacing-10xl);
}

.mt-11xl {
  margin-top: var(--redo-spacing-11xl);
}

.mr-11xl {
  margin-right: var(--redo-spacing-11xl);
}

.mb-11xl {
  margin-bottom: var(--redo-spacing-11xl);
}

.ml-11xl {
  margin-left: var(--redo-spacing-11xl);
}

.m-11xl {
  margin: var(--redo-spacing-11xl);
}

.mx-11xl {
  margin-left: var(--redo-spacing-11xl);
  margin-right: var(--redo-spacing-11xl);
}

.my-11xl {
  margin-top: var(--redo-spacing-11xl);
  margin-bottom: var(--redo-spacing-11xl);
}

.pt-11xl {
  padding-top: var(--redo-spacing-11xl);
}

.pr-11xl {
  padding-right: var(--redo-spacing-11xl);
}

.pb-11xl {
  padding-bottom: var(--redo-spacing-11xl);
}

.pl-11xl {
  padding-left: var(--redo-spacing-11xl);
}

.p-11xl {
  padding: var(--redo-spacing-11xl);
}

.px-11xl {
  padding-left: var(--redo-spacing-11xl);
  padding-right: var(--redo-spacing-11xl);
}

.py-11xl {
  padding-top: var(--redo-spacing-11xl);
  padding-bottom: var(--redo-spacing-11xl);
}

.gap-11xl {
  gap: var(--redo-spacing-11xl);
}

.row-gap-11xl {
  row-gap: var(--redo-spacing-11xl);
}

.column-gap-11xl {
  column-gap: var(--redo-spacing-11xl);
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
