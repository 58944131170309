.full-width {
  width: 100%;
}

.split-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: var(--redo-spacing);
}

.split-card {
  width: 100%;
  background-color: var(--redo-colors-background-bg-primary);
  color: var(--redo-colors-foreground-fg-quarterary-500);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  border-radius: var(--redo-corner-medium-radius);
  display: flex;
  flex-direction: column;
  padding: calc(var(--redo-spacing) * 2);
  gap: calc(var(--redo-spacing) * 2);
  flex: 1;
  padding: 0;
  margin-bottom: var(--redo-spacing);
}

.action-button {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
  max-height: calc(var(--redo-spacing) * 2);
}

.action-buttons {
  display: flex;
  gap: var(--redo-spacing);
}

.icon {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
}

.launch-icon {
  height: 12px;
  width: 12px;
}

.plus-icon {
  position: relative;
  top: 2px;
}

.treatment-dropdown-text {
  background-color: var(--redo-colors-background-bg-error-solid);
}

.menu-icon {
  height: var(--redo-icon-large-size);
  width: var(--redo-icon-large-size);
  color: var(--redo-component-colors-utility-gray-utility-gray-400);
}

.treatment-actions {
  width: fit-content;
  display: flex;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding: var(--redo-spacing);
  margin: calc(var(--redo-spacing) * 2) calc(var(--redo-spacing) * 2) 0
    calc(var(--redo-spacing) * 2);
}

.header-left {
  flex: 1;
}

.header-right {
  display: flex;
  gap: var(--redo-spacing);
}

.preview-link {
  color: var(--redo-colors-text-text-primary-900);
}

.split-name {
  color: var(--redo-colors-text-text-primary-900);
  font-size: calc(var(--redo-title-large-text-size) * 1.25);
  font-weight: var(--redo-font-weight-semibold);
  padding: var(--redo-spacing);
}

.edit-name-icon {
  display: flex;
  gap: var(--redo-spacing);
  justify-content: center;
  align-items: center;
}

.split-indicator {
  display: inline-block;
  margin-left: var(--redo-spacing);
  margin-right: var(--redo-spacing);
  border-radius: 6px;
  padding: 4px var(--redo-spacing) 4px 0;
  font-size: var(--redo-body-small-text-size);
  vertical-align: middle;
  line-height: normal;
  position: relative;
  bottom: 4px;

  &.active {
    border: var(--redo-border-width) solid
      var(--redo-component-colors-utility-success-utility-success-200);
    color: var(--redo-component-colors-utility-success-utility-success-700);
    background-color: var(
      --redo-component-colors-utility-success-utility-success-50
    );

    .split-status {
      background-color: var(
        --redo-component-colors-utility-success-utility-success-500
      );
      color: var(--redo-colors-foreground-fg-success-secondary);
    }
  }

  &.inactive {
    border: var(--redo-border-width) solid
      var(--redo-component-colors-utility-error-utility-error-200);
    color: var(--redo-component-colors-utility-error-utility-error-700);
    background-color: var(
      --redo-component-colors-utility-error-utility-error-50
    );

    .split-status {
      background-color: var(
        --redo-component-colors-utility-error-utility-error-700
      );
      color: var(--redo-component-colors-utility-error-utility-error-700);
    }
  }
}

.split-status {
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
  height: 8px;
  width: 8px;
  position: relative;
  bottom: 2px;
}

.split-details {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
  display: block;
}

.split-name,
.split-status,
.split-details {
  margin: 0 var(--redo-spacing);
}

.data-row {
  padding: var(--redo-spacing);
  margin: 0 calc(var(--redo-spacing) * 2) var(--redo-spacing) 0;
  padding-left: 0;
}

.analytics-container {
  width: 100%;
  position: relative;
  left: -8px;
  margin-right: 128px;
  display: flex;
  justify-content: space-between;
}

.spaced-out-button {
  padding: 14px 0;
  font-size: var(--redo-body-small-text-size);
  font-weight: var(--redo-font-weight-semibold);
  width: 100%;
}

.treatments-row {
  border-radius: var(--redo-corner-medium-radius);
  background-color: var(--redo-colors-background-bg-primary);
  margin: 0;
}

.treatments-table {
  width: 100%;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  border-bottom-right-radius: var(--redo-corner-medium-radius);
  border-bottom-left-radius: var(--redo-corner-medium-radius);
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
}

.treatments-table thead {
  background-color: var(--redo-colors-background-bg-secondary);
  font-weight: 400;
}

.treatments-table td:first-child {
  width: fit-content;
  text-align: left;
}

.treatments-table td:not(:first-child) {
  width: auto;
  text-align: right;
}

.treatments-table td {
  padding: calc(var(--redo-spacing) * 2);
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
}

.treatments-table tbody td {
  border-top: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  font-weight: 400;
}

.treatments-table td:last-child {
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  margin-left: 0;
}

.treatment-name {
  font-weight: var(--redo-font-weight-semibold);
  color: var(--redo-colors-text-text-primary-900);
}

.new-split-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--redo-spacing);
  height: 42px;
}

.winner {
  display: inline-block;
  margin-left: var(--redo-spacing);
  margin-right: var(--redo-spacing);
  border-radius: 4px;
  padding: 0 var(--redo-spacing);
  vertical-align: middle;
  line-height: normal;
  border: calc(var(--redo-border-width) * 2) solid
    var(--redo-component-colors-utility-brand-utility-brand-400);
  color: var(--redo-component-colors-utility-brand-utility-brand-400);
  font-weight: 500;
}

.treatment-link {
  color: var(--redo-colors-text-text-primary-900);
  text-decoration: none;
  font-size: large;
  margin-right: var(--redo-spacing);
}

.data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--redo-spacing);
}

.analytics-block {
  margin: 0 calc(var(--redo-spacing) * 5);
}

.analytics-label {
  font-size: var(--redo-body-medium-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
  white-space: nowrap;
}

.analytics-value {
  font-size: var(--redo-title-medium-text-size);
  font-weight: var(--redo-font-weight-semibold);
}

.positive-analytic {
  color: var(--redo-colors-success-600);
}

.negative-analytic {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}

.light-outline-button {
  border-color: var(--redo-colors-border-border-secondary) !important;
}

.split-page {
  height: 100%;
  width: 100%;
  background-color: white;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.split-content-container {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.split-sidebar-container {
  flex: 1;
  border-left: 1px solid var(--redo-colors-border-border-secondary);
  display: block;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
  min-width: 354px;
  padding-top: 13px;
}

.sidebar-section-header {
  color: var(--redo-colors-foreground-fg-quarterary-500);
  font-size: var(--redo-body-xsmall-text-size);
  padding-bottom: var(--redo-spacing);
}

.sidebar-section-title {
  padding: calc(var(--redo-spacing) * 2);
  padding-bottom: var(--redo-spacing);
  font-size: var(--redo-title-small-text-size);
  font-weight: var(--redo-font-weight-semibold);
  color: var(--redo-color-text-secondary);
}

.sidebar-content {
  width: 100%;
}

.sidebar-content-section {
  padding: calc(var(--redo-spacing) * 2);
}

.sidebar-setting-row {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--redo-spacing) * 2) 0;
}

.cart-card-slider {
  position: relative;
  top: -22px;
}

.sidebar-padded-row {
  padding-top: calc(var(--redo-spacing) * 2);
}

.sidebar-style-selector {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  padding: calc(var(--redo-spacing) * 2) 0;
  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
}

.general-settings-toggle {
  padding: calc(var(--redo-spacing) * 2) 0;
}

.add-button {
  color: var(--redo-colors-foreground-fg-brand-primary-600);
}

.split-header-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
}

.split-form-actions {
  padding: var(--redo-spacing);
  & > Button {
    margin-left: calc(var(--redo-spacing) * 2);
  }
  display: flex;
}

.new-split-modal {
  width: 800px;
}

.modal-left-button {
  margin-right: auto;
}

.modal-description {
  font-weight: var(--redo-font-weight-semibold);
  font-size: var(--redo-body-large-text-size);
}

.split-type-container {
  text-align: center;
  height: 75%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.split-type-selection {
  display: block;
  margin: 0 var(--redo-spacing);
}

.split-type-description {
  text-align: left;
  padding: var(--redo-spacing);
  margin-bottom: calc(var(--redo-spacing) * 2);
  font-weight: var(--redo-font-weight-semibold);
}

.selected-split-type {
  border: 2px solid var(--redo-utility-brand);
  border-radius: var(--redo-corner-large-radius);
}

.split-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-color: var(--redo-colors-background-bg-tertiary);
  min-width: 0px;
  overflow-x: auto;
}

.split-footer-container {
  display: flex;
  justify-content: flex-start;
  padding: var(--redo-spacing);
  gap: var(--redo-spacing);
  border-top: 1px solid var(--redo-colors-border-border-secondary);
}

.treatment-name-button {
  padding: 0 var(--redo-spacing);
  border-radius: var(--redo-corner-small-radius);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--redo-contrast-low-text-color);
  font-weight: var(--redo-font-weight-semibold);
  cursor: pointer;

  &.selected {
    background-color: var(--redo-primary-extra-light-color);
    color: var(--redo-primary-color);
  }

  &:hover {
    color: var(--redo-primary-color);
  }

  &.selected:hover {
    background-color: var(--redo-primary-extra-light-color);
  }
}

.rich-text-editor {
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: var(--redo-corner-medium-radius);
  padding: var(--redo-spacing);
  margin: var(--redo-spacing) 0;

  div > div > ol {
    line-height: 16px;

    li[data-list="bullet"] {
      list-style-type: disc;
      list-style-position: inside;
    }

    li[data-list="ordered"] {
      list-style-type: decimal;
      list-style-position: inside;
    }
  }
}

.rich-text-label {
  font-size: var(--redo-body-small-text-size);
  color: var(--redo-colors-text-text-tertiary-600);
  margin-bottom: var(--redo-spacing);
}

.editor-style-icon {
  margin: 0 var(--redo-spacing);
}

.hoverable {
  cursor: pointer;
  color: var(--redo-utility-gray);
  font-weight: 400;
}

.navigate-icon {
  height: 24px;
  width: 24px;
  position: relative;
  top: 6px;
  color: var(--redo-utility-gray);
}

.coverage-preview {
  background-color: var(--redo-colors-gray-true-350);
  border-radius: var(--redo-corner-medium-radius);
  min-width: 600px;
  min-height: 400px;
  box-shadow: 0 5px 15px var(--redo-colors-gray-neutral-300);
}

.preview-style-selector {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin-top: calc(var(--redo-spacing) * 8);
}

.preview-style-selector-sizer {
  min-width: 12em;
}

.skeleton-block {
  border-radius: var(--redo-corner-large-radius);
  margin: var(--redo-spacing);

  &.light {
    background-color: var(--redo-colors-gray-true-100);
  }

  &.medium {
    background-color: var(--redo-colors-gray-true-200);
  }

  &.dark {
    background-color: var(--redo-colors-gray-true-400);
  }
}

.cart-preview {
  position: relative;
  min-height: 485px;
  padding: calc(var(--redo-spacing) * 2);
}

.cart-container {
  width: 66%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--redo-surface-light-color);
  padding: var(--redo-spacing);
  border-radius: 0 var(--redo-corner-medium-radius)
    var(--redo-corner-medium-radius) 0;
  overflow-y: scroll;
  scrollbar-width: none;
}

.full-cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    gap: var(--redo-spacing);
  }
}

.full-cart-container {
  height: 100%;
  min-height: 485px;
  background-color: var(--redo-surface-light-color);
  padding: var(--redo-spacing);
  border-radius: var(--redo-corner-medium-radius);
  overflow-y: scroll;
  scrollbar-width: none;
  padding: calc(var(--redo-spacing) * 2);
}

.checkout-button-container {
  display: flex;
  justify-content: space-between;
}

.cart-total {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.fake-product {
  display: flex;
  gap: var(--redo-spacing);
  border-bottom: 2px solid var(--redo-colors-gray-true-100);
}

.fake-redo-toggle {
  padding: var(--redo-spacing);
  margin: var(--redo-spacing);
}

.fake-product-image {
  height: 100%;
  object-fit: cover;
}

.fake-product-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.fake-product-price {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
}

.checkout-button {
  padding: var(--redo-spacing);
  margin: var(--redo-spacing);
  margin-right: calc(var(--redo-spacing) * 2);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--redo-color-text-primary);
  color: var(--redo-text-color);
  border-radius: var(--redo-corner-small-radius);
  min-width: 270px;
}

.checkout-preview {
  position: relative;
  padding: calc(var(--redo-spacing) * 2);
  background-color: var(--redo-colors-background-bg-primary);
  border-radius: var(--redo-corner-medium-radius);
}

.checkout-container {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--redo-colors-gray-true-100);
  padding: var(--redo-spacing);
  border-radius: 0 var(--redo-corner-medium-radius)
    var(--redo-corner-medium-radius) 0;
  overflow-y: scroll;
  scrollbar-width: none;
}

.pay-now-button {
  padding: var(--redo-spacing);
  margin: var(--redo-spacing);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  background-color: var(--redo-color-text-primary);
  color: var(--redo-text-color);
  border-radius: var(--redo-corner-small-radius);
}

.fake-checkout-container {
  display: flex;
  gap: var(--redo-spacing);
}

.fake-checkout-image {
  height: 48px;
  width: 48px;
  position: relative;
  top: -8px;
}

.fake-checkout-text-container {
  display: flex;
  flex-direction: column;
  gap: var(--redo-spacing);
  font-size: small;
  line-height: 12px;
  flex: 1;
}

.fake-checkout-title {
  font-weight: var(--redo-font-weight-semibold);
}

.fake-checkout-checkbox {
  text-align: right;
  justify-content: flex-end;
}

.pricing-setting-block {
  display: grid;
  grid-template-columns: 2fr 3fr 1fr;
  gap: calc(var(--redo-spacing) * 2);
  margin-bottom: calc(var(--redo-spacing) * 2);
  height: 40px;
  vertical-align: middle;
}

.pricing-setting-block > *:nth-child(3n + 1) {
  justify-self: end;
}

.error-text {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}

.icon-button-container {
  display: flex;
  gap: calc(var(--redo-spacing) * 1.5);
}
