.divider {
  border-bottom: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.divider-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 8px 0;
  color: var(--redo-colors-text-text-secondary);
}

.divider-text::before,
.divider-text::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--redo-colors-border-border-secondary);
  margin: 0 8px; /* Adjust spacing around the text */
}
