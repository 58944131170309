.checkbox {
  display: none;
}

.checkbox:checked + .switchEnabled {
  background-color: var(--redo-colors-background-bg-alternate);
}

.checkbox:checked + .switch0 .circle {
  &.xs {
    left: 13.6px;
  }

  &.sm {
    left: 18px;
  }
}

.vertical-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: var(--redo-spacing);
}

.horizontal-container {
  display: flex;
  cursor: pointer;
  gap: var(--redo-spacing);
  align-items: flex-start;
}

.circle {
  background-color: var(--redo-colors-background-bg-primary);
  position: absolute;
  border-radius: 50%;
  transition: left 200ms;

  &.xs {
    top: 1.6px;
    left: 1.6px;

    width: 12.8px;
    height: 12.8px;

    box-shadow:
      0px 0.8px 2.4px 0px #1018281a,
      0px 0.8px 1.6px 0px #1018280f;
  }

  &.sm {
    top: 2px;
    left: 2px;

    width: 16px;

    height: 16px;
    box-shadow:
      0px 1px 3px 0px #1018281a,
      0px 1px 2px 0px #1018280f;
  }
}

.switch0 {
  display: block;
  background-color: var(--redo-colors-foreground-fg-senary-300);
  border-radius: var(--redo-corner-medium-radius);
  position: relative;
  transition: background-color 200ms;

  &.switch-disabled {
    background-color: var(--redo-colors-background-bg-tertiary);
    cursor: not-allowed;
  }

  &.xs {
    width: 28px;
    height: 16px;
  }

  &.sm {
    width: 36px;
    height: 20px;
  }
}

.text-switch-container {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing);
  padding: var(--redo-spacing);
  border-radius: var(--redo-corner-small-radius);
  background-color: var(--redo-surface-medium-color);
  user-select: none;
}

.text-switch-option {
  flex: 1;
  padding: var(--redo-spacing);
  border-radius: var(--redo-corner-small-radius);
  text-align: center;
  cursor: pointer;
}

.text-switch-option-selected {
  background-color: var(--redo-colors-background-bg-primary);
  cursor: default;
}
