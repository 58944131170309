.bubble {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .initials-wrapper p {
    font-weight: bold;
    color: var(--redo-colors-base-black);
  }
}

.grey-out {
  opacity: 0.2;
}

.container {
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contrast-border {
  &:before {
    content: " ";
    position: absolute;
    border-radius: 50%;
    border: 0.5px solid #00000014;
    pointer-events: none; /* user can't click on it */
  }
  &.micro:before {
    width: 24px;
    height: 24px;
  }
  &.small:before {
    width: 32px;
    height: 32px;
  }
  &.medium-small:before {
    width: 40px;
    height: 40px;
  }
  &.medium:before {
    width: 56px;
    height: 56px;
  }
  &.large:before {
    width: 64px;
    height: 64px;
  }
}

.x-tiny {
  height: 12px;
  width: 12px;
  .initials-wrapper p {
    font-size: 8px;
  }
}

.tiny {
  height: 16px;
  width: 16px;
  .initials-wrapper p {
    font-size: 8px;
  }
}

.micro {
  height: 24px;
  width: 24px;
  .initials-wrapper p {
    font-size: 10px;
  }
}

.small {
  height: 32px;
  width: 32px;
  .initials-wrapper p {
    font-size: 12px;
  }
}

.medium-small {
  height: 40px;
  width: 40px;
  .initials-wrapper p {
    font-size: 12px;
  }
}

.medium {
  height: 56px;
  width: 56px;
  .initials-wrapper p {
    font-size: 16px;
  }
}

.large {
  height: 64px;
  width: 64px;
  .initials-wrapper p {
    font-size: 18px;
  }
}
