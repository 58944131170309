/* Mimicks the MUI fieldset styles */
.date-label {
  font-size: 14px;
  transform: translate(0, -14px) scale(0.75);
  color: rgba(0, 0, 0, 0.6);
  width: min-content;
  text-wrap: nowrap;
  z-index: 100;
  background-color: var(--redo-colors-background-bg-primary);
  padding: 4px;
  position: absolute;
}

.date-label-error {
  color: var(--redo-component-colors-utility-error-utility-error-400);
}

.full-width {
  width: 100%;
}

/*
 * Highlights the daterange picker a light red color if the start date is after the end date
 * to warn the user that the selection is invalid.
 */
:global(.react-daterange-picker__invalid-range) {
  /* Must override a child element to change the background color */
  :global(.react-daterange-picker__wrapper) {
    background-color: rgba(255, 0, 0, 0.1);
  }
}

:global(.react-daterange-picker__wrapper) {
  color: var(--redo-colors-text-text-primary-900) !important;
  border-radius: 10px;
  padding: 12px 20px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary) !important;
  outline: var(--redo-active-border-width) solid transparent;
  background-color: var(--redo-colors-background-bg-primary);

  &:hover {
    border-color: var(--redo-colors-border-border-secondary);
  }
}

/** Mock arbiter small input look and feel (but we have to smash the ReactDatePicker to make it work) */
.smaller {
  font-size: 12px;
  font-weight: medium;

  & > div {
    border-radius: 6px;
    padding: 0px 20px 0px 20px;
    max-height: 28px;
  }
}

:global(.react-date-picker__wrapper) {
  border-radius: 10px;
  padding: 12px 20px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary) !important;
  outline: var(--redo-active-border-width) solid transparent;
  background-color: var(--redo-colors-background-bg-primary);

  &:hover {
    border-color: var(--redo-colors-border-border-secondary_hover);
  }
}

:global(.mimic-mui .react-date-picker__wrapper) {
  border: var(--redo-border-width) solid rgba(0, 0, 0, 0.23);

  &:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
}

:global(.react-date-picker.error .react-date-picker__wrapper) {
  border-color: var(--redo-component-colors-utility-error-utility-error-400);
  color: var(--redo-component-colors-utility-error-utility-error-400);

  input::placeholder {
    color: var(---redo-colors-text-text-placeholder);
    opacity: 0.66;
  }
}

:global(.react-daterange-picker__calendar) {
  inset: 120% auto auto 0px !important;
  width: auto;
  z-index: 100 !important;
}

:global(.react-date-picker__calendar) {
  inset: 120% auto auto 0px !important;
  width: auto;
  z-index: 100 !important;
}

:global(.react-calendar) {
  /* padding ensures the squares are the same size */
  padding: 24px 27px;
  border-radius: 10px;
  * {
    font-family: var(--redo-body-font-family);
  }
}

:global(.react-daterange-picker__button:enabled) {
  &:hover {
    * {
      stroke: var(
        --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg
      );
    }
  }
  * {
    stroke: var(--redo-colors-text-text-primary-900);
  }
}

:global(.react-date-picker__button:enabled:hover) {
  * {
    stroke: var(
      --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg
    );
  }
}

:global(.react-calendar__navigation) {
  text-align: center;
}

:global(.react-daterange-picker__range-divider) {
  margin: 0px 6px;
}

:global(.react-date-picker__range-divider) {
  margin: 0px 6px;
}

:global(.react-calendar__tile) {
  color: var(--redo-colors-text-text-secondary-700);
  background: none;
  line-height: 22px;
  position: relative;
  border-radius: 42px;

  &:disabled {
    border-radius: 0;
  }
}

:global(
  .react-calendar__tile:enabled:hover:not(.react-calendar__tile--active)
) {
  color: var(--redo-colors-text-text-secondary-700);
  background: #efecfd;
}

:global(.react-calendar__tile--hover) {
  color: var(--redo-colors-text-text-secondary-700);
  background: #efecfd;
}

:global(.react-calendar--selectRange) {
  :global(.react-calendar__tile--hover) {
    color: var(--redo-colors-text-text-secondary-700);
    background: #efecfd;
  }
}

:global(.react-calendar__navigation button:enabled:hover),
:global(.react-calendar__navigation button:enabled:focus) {
  background: #efecfd;
}

:global(.react-calendar__tile--active),
:global(.react-calendar__tile--hover) {
  /* color: white;
  background-color: var(--redo-primary-color); */
  color: var(--redo-colors-text-text-secondary-700);
  background: #efecfd;
  border-radius: 0;
}

:global(.react-calendar__tile--active:enabled:hover),
:global(.react-calendar__tile--active:enabled:focus) {
  /* color: white;
  background-color: var(--redo-primary-color); */
  color: var(--redo-colors-text-text-secondary-700);
  background: #efecfd;
  filter: brightness(0.9);
}

:global(.react-calendar__navigation__arrow) {
  font-size: 22px;
  border-radius: 44px;
}

:global(.react-calendar__navigation__label) {
  padding: 0px 8px;
  border-radius: 10px;
}

:global(.react-calendar__tile:disabled) {
  cursor: disabled;
}

:global(.react-daterange-picker__inputGroup__input:invalid) {
  background: transparent;
}

:global(.react-date-picker__inputGroup__input:invalid) {
  background: transparent;
}

:global(.react-calendar__month-view__days) {
  /* Styles for ends of selected range */
  :global(
    .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverStart),
  :global(
    .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverEnd),
  :global(
    .react-calendar__tile--rangeBothEnds:not(.react-calendar__tile--hover)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds) {
    color: var(--redo-colors-background-bg-primary) !important;
    filter: unset !important;
    * {
      position: relative;
    }
    &::before {
      top: 0px;
      left: 0px;
      content: "";
      width: 42px;
      height: 42px;
      position: absolute;
      border-radius: 42px;
      background-color: var(--redo-primary-color);
    }
  }

  /* Start of range */
  :global(
    .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverStart) {
    border-radius: 42px 0 0 42px;
  }

  /* End of range */
  :global(
    .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverEnd) {
    border-radius: 0 42px 42px 0;
  }

  /* Both ends */
  :global(
    .react-calendar__tile--rangeBothEnds:not(.react-calendar__tile--hover)
  ),
  :global(.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds) {
    border-radius: 42px;
  }
}

:global(.react-date-picker__inputGroup__divider) {
  color: var(--redo-colors-text-text-placeholder);
}
