:root,
:host {
  --redo-colors-base-white: #ffffff;
  --redo-colors-base-black: #000000;
  --redo-colors-gray-light-mode-25: #fcfcfc;
  --redo-colors-gray-blue-25: #fcfcfd;
  --redo-colors-gray-cool-25: #fcfcfd;
  --redo-colors-gray-modern-25: #fcfcfd;
  --redo-colors-gray-neutral-25: #fcfcfd;
  --redo-colors-gray-light-mode-50: #fafafa;
  --redo-colors-gray-neutral-50: #f9fafb;
  --redo-colors-gray-light-mode-100: #f5f5f5;
  --redo-colors-gray-light-mode-200: #e5e5e5;
  --redo-colors-gray-light-mode-300: #d6d6d6;
  --redo-colors-gray-light-mode-400: #a3a3a3;
  --redo-colors-gray-light-mode-500: #737373;
  --redo-colors-gray-light-mode-600: #525252;
  --redo-colors-gray-light-mode-700: #424242;
  --redo-colors-gray-light-mode-800: #292929;
  --redo-colors-gray-light-mode-900: #141414;
  --redo-colors-gray-light-mode-950: #0f0f0f;
  --redo-colors-brand-25: #f9f9ff;
  --redo-colors-brand-50: #ebe9fd;
  --redo-colors-brand-100: #dad4fa;
  --redo-colors-brand-200: #c5bbf7;
  --redo-colors-brand-300: #a393f2;
  --redo-colors-brand-400: #8671ed;
  --redo-colors-brand-500: #5f45e2;
  --redo-colors-brand-600: #4d35c6;
  --redo-colors-brand-700: #3e28ad;
  --redo-colors-brand-800: #291784;
  --redo-colors-brand-900: #1b0d63;
  --redo-colors-brand-950: #11064a;
  --redo-colors-error-25: #fffbfa;
  --redo-colors-error-50: #fef3f2;
  --redo-colors-error-100: #fee4e2;
  --redo-colors-error-200: #fecdca;
  --redo-colors-error-300: #fda29b;
  --redo-colors-error-400: #f97066;
  --redo-colors-error-500: #f04438;
  --redo-colors-error-600: #d92d20;
  --redo-colors-error-700: #b42318;
  --redo-colors-error-800: #912018;
  --redo-colors-error-900: #7a271a;
  --redo-colors-error-950: #55160c;
  --redo-colors-warning-25: #fffcf5;
  --redo-colors-warning-50: #fffaeb;
  --redo-colors-warning-100: #fef0c7;
  --redo-colors-warning-200: #fedf89;
  --redo-colors-warning-300: #fec84b;
  --redo-colors-warning-400: #fdb022;
  --redo-colors-warning-500: #f79009;
  --redo-colors-warning-600: #dc6803;
  --redo-colors-warning-700: #b54708;
  --redo-colors-warning-800: #93370d;
  --redo-colors-warning-900: #7a2e0e;
  --redo-colors-warning-950: #4e1d09;
  --redo-colors-success-25: #f6fef9;
  --redo-colors-success-50: #ecfdf3;
  --redo-colors-success-100: #dcfae6;
  --redo-colors-success-200: #abefc6;
  --redo-colors-success-300: #75e0a7;
  --redo-colors-success-400: #47cd89;
  --redo-colors-success-500: #17b26a;
  --redo-colors-success-600: #079455;
  --redo-colors-success-700: #067647;
  --redo-colors-success-800: #085d3a;
  --redo-colors-success-900: #074d31;
  --redo-colors-success-950: #053321;
  --redo-colors-gray-blue-50: #f8f9fc;
  --redo-colors-gray-blue-100: #eaecf5;
  --redo-colors-gray-blue-200: #d5d9eb;
  --redo-colors-gray-blue-300: #b3b8db;
  --redo-colors-gray-blue-400: #717bbc;
  --redo-colors-gray-blue-500: #4e5ba6;
  --redo-colors-gray-blue-600: #3e4784;
  --redo-colors-gray-blue-700: #363f72;
  --redo-colors-gray-blue-800: #293056;
  --redo-colors-gray-blue-900: #101323;
  --redo-colors-gray-blue-950: #0d0f1c;
  --redo-colors-gray-cool-50: #f9f9fb;
  --redo-colors-gray-cool-100: #eff1f5;
  --redo-colors-gray-cool-200: #dcdfea;
  --redo-colors-gray-cool-300: #b9c0d4;
  --redo-colors-gray-cool-400: #7d89b0;
  --redo-colors-gray-cool-500: #5d6b98;
  --redo-colors-gray-cool-600: #4a5578;
  --redo-colors-gray-cool-700: #404968;
  --redo-colors-gray-cool-800: #30374f;
  --redo-colors-gray-cool-900: #111322;
  --redo-colors-gray-modern-50: #f8fafc;
  --redo-colors-gray-modern-100: #eef2f6;
  --redo-colors-gray-modern-200: #e3e8ef;
  --redo-colors-gray-modern-300: #cdd5df;
  --redo-colors-gray-modern-400: #9aa4b2;
  --redo-colors-gray-modern-500: #697586;
  --redo-colors-gray-modern-600: #4b5565;
  --redo-colors-gray-modern-700: #364152;
  --redo-colors-gray-modern-800: #202939;
  --redo-colors-gray-modern-900: #121926;
  --redo-colors-gray-neutral-100: #f3f4f6;
  --redo-colors-gray-neutral-200: #e5e7eb;
  --redo-colors-gray-neutral-300: #d2d6db;
  --redo-colors-gray-neutral-400: #9da4ae;
  --redo-colors-gray-neutral-500: #6c737f;
  --redo-colors-gray-neutral-600: #4d5761;
  --redo-colors-gray-neutral-700: #384250;
  --redo-colors-gray-neutral-800: #1f2a37;
  --redo-colors-gray-neutral-900: #111927;
  --redo-colors-gray-iron-25: #fcfcfc;
  --redo-colors-gray-iron-50: #fafafa;
  --redo-colors-gray-iron-100: #f4f4f5;
  --redo-colors-gray-iron-200: #e4e4e7;
  --redo-colors-gray-iron-300: #d1d1d6;
  --redo-colors-gray-iron-400: #a0a0ab;
  --redo-colors-gray-iron-500: #70707b;
  --redo-colors-gray-iron-600: #51525c;
  --redo-colors-gray-iron-700: #3f3f46;
  --redo-colors-gray-iron-800: #26272b;
  --redo-colors-gray-iron-900: #1a1a1e;
  --redo-colors-gray-true-25: #fcfcfc;
  --redo-colors-gray-true-50: #fafafa;
  --redo-colors-gray-true-100: #f5f5f5;
  --redo-colors-gray-true-200: #e5e5e5;
  --redo-colors-gray-true-300: #d6d6d6;
  --redo-colors-gray-true-350: #b1b1b1;
  --redo-colors-gray-true-400: #a3a3a3;
  --redo-colors-gray-true-500: #737373;
  --redo-colors-gray-true-600: #525252;
  --redo-colors-gray-true-700: #424242;
  --redo-colors-gray-true-800: #292929;
  --redo-colors-gray-true-900: #141414;
  --redo-colors-gray-true-950: #0f0f0f;
  --redo-colors-gray-warm-25: #fdfdfc;
  --redo-colors-gray-warm-50: #fafaf9;
  --redo-colors-gray-warm-100: #f5f5f4;
  --redo-colors-gray-warm-200: #e7e5e4;
  --redo-colors-gray-warm-300: #d7d3d0;
  --redo-colors-gray-warm-400: #a9a29d;
  --redo-colors-gray-warm-500: #79716b;
  --redo-colors-gray-warm-600: #57534e;
  --redo-colors-gray-warm-700: #44403c;
  --redo-colors-gray-warm-800: #292524;
  --redo-colors-gray-warm-900: #1c1917;
  --redo-colors-gray-warm-950: #171412;
  --redo-colors-moss-25: #fafdf7;
  --redo-colors-moss-50: #f5fbee;
  --redo-colors-moss-100: #e6f4d7;
  --redo-colors-moss-200: #ceeab0;
  --redo-colors-moss-300: #acdc79;
  --redo-colors-moss-400: #86cb3c;
  --redo-colors-moss-500: #669f2a;
  --redo-colors-moss-600: #4f7a21;
  --redo-colors-moss-700: #3f621a;
  --redo-colors-moss-800: #335015;
  --redo-colors-moss-900: #2b4212;
  --redo-colors-moss-950: #1a280b;
  --redo-colors-green-light-25: #fafef5;
  --redo-colors-green-light-50: #f3fee7;
  --redo-colors-green-light-100: #e3fbcc;
  --redo-colors-green-light-200: #d0f8ab;
  --redo-colors-green-light-300: #a6ef67;
  --redo-colors-green-light-400: #85e13a;
  --redo-colors-green-light-500: #66c61c;
  --redo-colors-green-light-600: #4ca30d;
  --redo-colors-green-light-700: #3b7c0f;
  --redo-colors-green-light-800: #326212;
  --redo-colors-green-light-900: #2b5314;
  --redo-colors-green-25: #f6fef9;
  --redo-colors-green-50: #edfcf2;
  --redo-colors-green-100: #d3f8df;
  --redo-colors-green-200: #aaf0c4;
  --redo-colors-green-300: #73e2a3;
  --redo-colors-green-400: #3ccb7f;
  --redo-colors-green-500: #16b364;
  --redo-colors-green-600: #099250;
  --redo-colors-green-700: #087443;
  --redo-colors-green-800: #095c37;
  --redo-colors-green-900: #084c2e;
  --redo-colors-green-950: #052e1c;
  --redo-colors-teal-50: #f0fdf9;
  --redo-colors-teal-100: #ccfbef;
  --redo-colors-teal-200: #99f6e0;
  --redo-colors-teal-300: #5fe9d0;
  --redo-colors-teal-400: #2ed3b7;
  --redo-colors-teal-500: #15b79e;
  --redo-colors-teal-600: #0e9384;
  --redo-colors-teal-700: #107569;
  --redo-colors-teal-800: #125d56;
  --redo-colors-teal-900: #134e48;
  --redo-colors-cyan-50: #ecfdff;
  --redo-colors-cyan-100: #cff9fe;
  --redo-colors-cyan-200: #a5f0fc;
  --redo-colors-cyan-300: #67e3f9;
  --redo-colors-cyan-400: #22ccee;
  --redo-colors-cyan-500: #06aed4;
  --redo-colors-cyan-600: #088ab2;
  --redo-colors-cyan-700: #0e7090;
  --redo-colors-cyan-800: #155b75;
  --redo-colors-cyan-900: #164c63;
  --redo-colors-cyan-950: #0d2d3a;
  --redo-colors-blue-light-25: #f5fbff;
  --redo-colors-blue-light-50: #f0f9ff;
  --redo-colors-blue-light-100: #e0f2fe;
  --redo-colors-blue-light-200: #b9e6fe;
  --redo-colors-blue-light-300: #7cd4fd;
  --redo-colors-blue-light-400: #36bffa;
  --redo-colors-blue-light-500: #0ba5ec;
  --redo-colors-blue-light-600: #0086c9;
  --redo-colors-blue-light-700: #026aa2;
  --redo-colors-blue-light-800: #065986;
  --redo-colors-blue-light-900: #0b4a6f;
  --redo-colors-blue-light-950: #062c41;
  --redo-colors-blue-25: #f5faff;
  --redo-colors-blue-50: #eff8ff;
  --redo-colors-blue-100: #d1e9ff;
  --redo-colors-blue-200: #b2ddff;
  --redo-colors-blue-300: #84caff;
  --redo-colors-blue-400: #53b1fd;
  --redo-colors-blue-500: #2e90fa;
  --redo-colors-blue-600: #1570ef;
  --redo-colors-blue-700: #175cd3;
  --redo-colors-blue-800: #1849a9;
  --redo-colors-blue-900: #194185;
  --redo-colors-blue-950: #102a56;
  --redo-colors-blue-dark-25: #f5f8ff;
  --redo-colors-indigo-25: #f5f8ff;
  --redo-colors-blue-dark-50: #eff4ff;
  --redo-colors-blue-dark-100: #d1e0ff;
  --redo-colors-blue-dark-200: #b2ccff;
  --redo-colors-blue-dark-300: #84adff;
  --redo-colors-blue-dark-400: #528bff;
  --redo-colors-blue-dark-500: #2970ff;
  --redo-colors-blue-dark-600: #155eef;
  --redo-colors-blue-dark-700: #004eeb;
  --redo-colors-blue-dark-800: #0040c1;
  --redo-colors-blue-dark-900: #00359e;
  --redo-colors-indigo-50: #eef4ff;
  --redo-colors-indigo-100: #e0eaff;
  --redo-colors-indigo-200: #c7d7fe;
  --redo-colors-indigo-300: #a4bcfd;
  --redo-colors-indigo-400: #8098f9;
  --redo-colors-indigo-500: #6172f3;
  --redo-colors-indigo-600: #444ce7;
  --redo-colors-indigo-700: #3538cd;
  --redo-colors-indigo-800: #2d31a6;
  --redo-colors-indigo-900: #2d3282;
  --redo-colors-indigo-950: #1f235b;
  --redo-colors-violet-25: #fbfaff;
  --redo-colors-violet-50: #f5f3ff;
  --redo-colors-violet-100: #ece9fe;
  --redo-colors-violet-200: #ddd6fe;
  --redo-colors-violet-300: #c3b5fd;
  --redo-colors-violet-400: #a48afb;
  --redo-colors-violet-500: #875bf7;
  --redo-colors-violet-600: #7839ee;
  --redo-colors-violet-700: #6927da;
  --redo-colors-violet-800: #5720b7;
  --redo-colors-violet-900: #491c96;
  --redo-colors-purple-25: #f9f9ff;
  --redo-colors-purple-50: #ebe9fd;
  --redo-colors-purple-100: #dad4fa;
  --redo-colors-purple-200: #c5bbf7;
  --redo-colors-purple-300: #a393f2;
  --redo-colors-purple-400: #8671ed;
  --redo-colors-purple-500: #5f45e2;
  --redo-colors-purple-600: #4d35c6;
  --redo-colors-purple-700: #3e28ad;
  --redo-colors-purple-800: #291784;
  --redo-colors-purple-900: #1b0d63;
  --redo-colors-purple-950: #11064a;
  --redo-colors-fuchsia-25: #fefaff;
  --redo-colors-fuchsia-50: #fdf4ff;
  --redo-colors-fuchsia-100: #fbe8ff;
  --redo-colors-fuchsia-200: #f6d0fe;
  --redo-colors-fuchsia-300: #eeaafd;
  --redo-colors-fuchsia-400: #e478fa;
  --redo-colors-fuchsia-500: #d444f1;
  --redo-colors-fuchsia-600: #ba24d5;
  --redo-colors-fuchsia-700: #9f1ab1;
  --redo-colors-fuchsia-800: #821890;
  --redo-colors-fuchsia-900: #6f1877;
  --redo-colors-pink-25: #fef6fb;
  --redo-colors-pink-50: #fdf2fa;
  --redo-colors-pink-100: #fce7f6;
  --redo-colors-pink-200: #fcceee;
  --redo-colors-pink-300: #faa7e0;
  --redo-colors-pink-400: #f670c7;
  --redo-colors-pink-500: #ee46bc;
  --redo-colors-pink-600: #dd2590;
  --redo-colors-pink-700: #c11574;
  --redo-colors-pink-800: #9e165f;
  --redo-colors-pink-900: #851651;
  --redo-colors-pink-950: #4e0d30;
  --redo-colors-ros\E9-25: #fff5f6;
  --redo-colors-ros\E9-50: #fff1f3;
  --redo-colors-ros\E9-100: #ffe4e8;
  --redo-colors-ros\E9-200: #fecdd6;
  --redo-colors-ros\E9-300: #fea3b4;
  --redo-colors-ros\E9-400: #fd6f8e;
  --redo-colors-ros\E9-500: #f63d68;
  --redo-colors-ros\E9-600: #e31b54;
  --redo-colors-ros\E9-700: #c01048;
  --redo-colors-ros\E9-800: #a11043;
  --redo-colors-ros\E9-900: #89123e;
  --redo-colors-ros\E9-950: #510b24;
  --redo-colors-orange-dark-25: #fff9f5;
  --redo-colors-orange-dark-50: #fff4ed;
  --redo-colors-orange-dark-100: #ffe6d5;
  --redo-colors-orange-dark-200: #ffd6ae;
  --redo-colors-orange-dark-300: #ff9c66;
  --redo-colors-orange-dark-400: #ff692e;
  --redo-colors-orange-dark-500: #ff4405;
  --redo-colors-orange-dark-600: #e62e05;
  --redo-colors-orange-dark-700: #bc1b06;
  --redo-colors-orange-dark-800: #97180c;
  --redo-colors-orange-dark-900: #771a0d;
  --redo-colors-orange-dark-950: #57130a;
  --redo-colors-orange-25: #fefaf5;
  --redo-colors-orange-50: #fef6ee;
  --redo-colors-orange-100: #fdead7;
  --redo-colors-orange-200: #f9dbaf;
  --redo-colors-orange-300: #f7b27a;
  --redo-colors-orange-400: #f38744;
  --redo-colors-orange-500: #ef6820;
  --redo-colors-orange-600: #e04f16;
  --redo-colors-orange-700: #b93815;
  --redo-colors-orange-800: #932f19;
  --redo-colors-orange-900: #772917;
  --redo-colors-orange-950: #511c10;
  --redo-colors-yellow-25: #fefdf0;
  --redo-colors-yellow-50: #fefbe8;
  --redo-colors-yellow-100: #fef7c3;
  --redo-colors-yellow-400: #fac515;
  --redo-colors-yellow-500: #eaaa08;
  --redo-colors-yellow-600: #ca8504;
  --redo-colors-yellow-700: #a15c07;
  --redo-colors-yellow-800: #854a0e;
  --redo-colors-yellow-900: #713b12;
  --redo-colors-gray-cool-950: #0e101b;
  --redo-colors-gray-modern-950: #0d121c;
  --redo-colors-gray-neutral-950: #0d121c;
  --redo-colors-gray-iron-950: #131316;
  --redo-colors-green-light-950: #15290a;
  --redo-colors-teal-25: #f6fefc;
  --redo-colors-teal-950: #0a2926;
  --redo-colors-cyan-25: #f5feff;
  --redo-colors-blue-dark-950: #002266;
  --redo-colors-violet-950: #2e125e;
  --redo-colors-fuchsia-950: #47104c;
  --redo-colors-yellow-950: #542c0d;
  --redo-colors-yellow-200: #feee95;
  --redo-colors-yellow-300: #fde272;
  --redo-spacing-0-0px: 0px;
  --redo-spacing-0-2px: 2px;
  --redo-spacing-1-4px: 4px;
  --redo-spacing-2-8px: 8px;
  --redo-spacing-3-12px: 12px;
  --redo-spacing-4-16px: 16px;
  --redo-spacing-5-20px: 20px;
  --redo-spacing-6-24px: 24px;
  --redo-spacing-8-32px: 32px;
  --redo-spacing-10-40px: 40px;
  --redo-spacing-12-48px: 48px;
  --redo-spacing-16-64px: 64px;
  --redo-spacing-20-80px: 80px;
  --redo-spacing-24-96px: 96px;
  --redo-spacing-32-128px: 128px;
  --redo-spacing-40-160px: 160px;
  --redo-spacing-48-192px: 192px;
  --redo-spacing-56-224px: 224px;
  --redo-spacing-64-256px: 256px;
  --redo-spacing-96-384px: 384px;
  --redo-spacing-80-320px: 320px;
  --redo-spacing-120-480px: 480px;
  --redo-spacing-160-640px: 640px;
  --redo-spacing-192-768px: 768px;
  --redo-spacing-256-1024px: 1024px;
  --redo-spacing-320-1280px: 1280px;
  --redo-spacing-360-1440px: 1440px;
  --redo-spacing-400-1600px: 1600px;
  --redo-spacing-480-1920px: 1920px;
  --redo-spacing-1-6px: 6px;
  --redo-colors-base-transparent: #ffffff;
  --redo-spacing-140-560px: 560px;
  --redo-spacing-180-720px: 720px;
  --redo-colors-gray-dark-mode-alpha-25: #ffffff;
  --redo-colors-gray-dark-mode-alpha-50: #ffffff;
  --redo-colors-gray-dark-mode-alpha-100: #ffffff;
  --redo-colors-gray-dark-mode-alpha-200: #ffffff;
  --redo-colors-gray-dark-mode-alpha-300: #ffffff;
  --redo-colors-gray-dark-mode-alpha-400: #ffffff;
  --redo-colors-gray-dark-mode-alpha-500: #ffffff;
  --redo-colors-gray-dark-mode-alpha-600: #ffffff;
  --redo-colors-gray-dark-mode-alpha-700: #ffffff;
  --redo-colors-gray-dark-mode-alpha-800: #ffffff;
  --redo-colors-gray-dark-mode-alpha-900: #ffffff;
  --redo-colors-gray-dark-mode-alpha-950: #ffffff;
  --redo-colors-gray-dark-mode-25: #fcfcfc;
  --redo-colors-gray-dark-mode-50: #fafafa;
  --redo-colors-gray-dark-mode-100: #f5f5f5;
  --redo-colors-gray-dark-mode-200: #e5e5e5;
  --redo-colors-gray-dark-mode-300: #d6d6d6;
  --redo-colors-gray-dark-mode-400: #a3a3a3;
  --redo-colors-gray-dark-mode-500: #737373;
  --redo-colors-gray-dark-mode-600: #525252;
  --redo-colors-gray-dark-mode-700: #424242;
  --redo-colors-gray-dark-mode-800: #292929;
  --redo-colors-gray-dark-mode-900: #141414;
  --redo-colors-gray-dark-mode-950: #0f0f0f;
  --redo-type-size-20: 20px;
  --redo-type-size-18: 18px;
  --redo-type-size-16: 16px;
  --redo-type-size-14: 14px;
  --redo-type-size-12: 12px;
  --redo-type-size-10: 10px;
  --redo-type-size-24: 24px;
  --redo-type-size-30: 30px;
  --redo-type-size-36: 36px;
  --redo-type-size-48: 48px;
  --redo-type-size-60: 60px;
  --redo-type-size-72: 72px;
}
