@import "./media";

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: calc(var(--redo-spacing) * 2);
  row-gap: calc(var(--redo-spacing) * 3);

  &:not(.stretch) {
    grid-template-rows: min-content;
  }
}

.span-1 {
  grid-column: span 1;
}

.span-2 {
  grid-column: span 2;
}

.span-3 {
  grid-column: span 3;
}

.span-4 {
  grid-column: span 4;
}

.span-5 {
  grid-column: span 5;
}

.span-6 {
  grid-column: span 6;
}

.span-7 {
  grid-column: span 7;
}

.span-8 {
  grid-column: span 8;
}

.span-9 {
  grid-column: span 9;
}

.span-10 {
  grid-column: span 10;
}

.span-11 {
  grid-column: span 11;
}

.span-12 {
  grid-column: span 12;
}

@media (--redo-medium-viewport) {
  .span-1-m {
    grid-column: span 1;
  }

  .span-2-m {
    grid-column: span 2;
  }

  .span-3-m {
    grid-column: span 3;
  }

  .span-4-m {
    grid-column: span 4;
  }

  .span-5-m {
    grid-column: span 5;
  }

  .span-6-m {
    grid-column: span 6;
  }

  .span-7-m {
    grid-column: span 7;
  }

  .span-8-m {
    grid-column: span 8;
  }

  .span-9-m {
    grid-column: span 9;
  }

  .span-10-m {
    grid-column: span 10;
  }

  .span-11-m {
    grid-column: span 11;
  }

  .span-12-m {
    grid-column: span 12;
  }
}

@media (--redo-large-viewport) {
  .span-1-l {
    grid-column: span 1;
  }

  .span-2-l {
    grid-column: span 2;
  }

  .span-3-l {
    grid-column: span 3;
  }

  .span-4-l {
    grid-column: span 4;
  }

  .span-5-l {
    grid-column: span 5;
  }

  .span-6-l {
    grid-column: span 6;
  }

  .span-7-l {
    grid-column: span 7;
  }

  .span-8-l {
    grid-column: span 8;
  }

  .span-9-l {
    grid-column: span 9;
  }

  .span-10-l {
    grid-column: span 10;
  }

  .span-11-l {
    grid-column: span 11;
  }

  .span-12-l {
    grid-column: span 12;
  }
}

@media (--redo-xlarge-viewport) {
  .span-1-xl {
    grid-column: span 1;
  }

  .span-2-xl {
    grid-column: span 2;
  }

  .span-3-xl {
    grid-column: span 3;
  }

  .span-4-xl {
    grid-column: span 4;
  }

  .span-5-xl {
    grid-column: span 5;
  }

  .span-6-xl {
    grid-column: span 6;
  }

  .span-7-xl {
    grid-column: span 7;
  }

  .span-8-xl {
    grid-column: span 8;
  }

  .span-9-xl {
    grid-column: span 9;
  }

  .span-10-xl {
    grid-column: span 10;
  }

  .span-11-xl {
    grid-column: span 11;
  }

  .span-12-xl {
    grid-column: span 12;
  }
}
