.color-circle {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #a6a9ba;
}

.popover {
  z-index: 2;
  right: 24px;

  &.above {
    bottom: 32px;
  }

  &.below {
    top: 66px;
  }
}

.basic-label > .popover {
  right: 10px;

  &.above {
    bottom: 32px;
  }

  &.below {
    top: 10px;
  }
}

.basic > .color-circle {
  width: 20px;
  height: 20px;
  border-color: #d6d6d6;
}
