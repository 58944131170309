@import "./primitives-colors.css";

:root,
:host,
.light-mode {
  --redo-colors-text-text-primary-on-brand: var(--redo-colors-base-white);
  --redo-colors-text-text-primary-900: var(--redo-colors-gray-light-mode-900);
  --redo-colors-text-text-tertiary-600: var(--redo-colors-gray-light-mode-600);
  --redo-colors-text-text-secondary-700: var(--redo-colors-gray-light-mode-700);
  --redo-colors-border-border-secondary: var(--redo-colors-gray-light-mode-200);
  --redo-colors-text-text-error-primary-600: var(--redo-colors-error-600);
  --redo-colors-border-border-error: var(--redo-colors-error-300);
  --redo-colors-background-bg-primary: var(--redo-colors-base-white);
  --redo-colors-background-bg-tertiary: var(--redo-colors-gray-light-mode-100);
  --redo-colors-foreground-fg-secondary-700: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-colors-background-bg-brand-primary: var(--redo-colors-brand-50);
  --redo-colors-background-bg-error-secondary: var(--redo-colors-error-100);
  --redo-colors-background-bg-warning-primary: var(--redo-colors-warning-50);
  --redo-colors-background-bg-warning-secondary: var(--redo-colors-warning-100);
  --redo-colors-background-bg-success-primary: var(--redo-colors-success-50);
  --redo-colors-background-bg-success-secondary: var(--redo-colors-success-100);
  --redo-colors-foreground-fg-warning-primary: var(--redo-colors-warning-600);
  --redo-colors-foreground-fg-success-primary: var(--redo-colors-success-600);
  --redo-colors-border-border-primary: var(--redo-colors-gray-light-mode-300);
  --redo-colors-text-text-warning-primary-600: var(--redo-colors-warning-600);
  --redo-colors-text-text-success-primary-600: var(--redo-colors-success-600);
  --redo-colors-foreground-fg-on-brand: var(--redo-colors-base-white);
  --redo-colors-text-text-white: var(--redo-colors-base-white);
  --redo-colors-background-bg-brand-solid: var(--redo-colors-brand-600);
  --redo-colors-background-bg-secondary-solid: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-colors-background-bg-error-solid: var(--redo-colors-error-600);
  --redo-colors-background-bg-warning-solid: var(--redo-colors-warning-600);
  --redo-colors-background-bg-success-solid: var(--redo-colors-success-600);
  --redo-colors-border-border-brand: var(--redo-colors-brand-300);
  --redo-colors-foreground-fg-success-secondary: var(--redo-colors-success-500);
  --redo-colors-background-bg-secondary_hover: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-colors-background-bg-primary_active: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-colors-foreground-fg-secondary_hover: var(
    --redo-colors-gray-light-mode-800
  );
  --redo-colors-background-bg-primary_hover: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-colors-background-bg-disabled: var(--redo-colors-gray-light-mode-100);
  --redo-colors-foreground-fg-primary-900: var(
    --redo-colors-gray-light-mode-900
  );
  --redo-colors-foreground-fg-disabled: var(--redo-colors-gray-light-mode-400);
  --redo-colors-text-text-disabled: var(--redo-colors-gray-light-mode-500);
  --redo-colors-text-text-secondary_hover: var(
    --redo-colors-gray-light-mode-800
  );
  --redo-component-colors-fg-action-primary: var(--redo-colors-orange-dark-500);
  --redo-colors-text-text-tertiary_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-colors-background-bg-active: var(--redo-colors-gray-light-mode-50);
  --redo-colors-background-bg-brand-solid_hover: var(--redo-colors-brand-700);
  --redo-colors-border-border-brand-solid: var(--redo-colors-brand-600);
  --redo-colors-border-border-disabled: var(--redo-colors-gray-light-mode-300);
  --redo-colors-border-border-error-solid: var(--redo-colors-error-600);
  --redo-colors-background-bg-error-primary: var(--redo-colors-error-50);
  --redo-colors-foreground-fg-quarterary-500: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-colors-foreground-fg-quarterary_hover: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-colors-foreground-fg-brand-secondary-500: var(--redo-colors-brand-500);
  --redo-colors-background-bg-brand-secondary: var(--redo-colors-brand-100);
  --redo-colors-foreground-fg-brand-primary-600: var(--redo-colors-brand-600);
  --redo-colors-foreground-fg-quinary-400: var(
    --redo-colors-gray-light-mode-400
  );
  --redo-colors-foreground-fg-senary-300: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-colors-foreground-fg-quinary_hover: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-colors-foreground-fg-error-primary: var(--redo-colors-error-600);
  --redo-colors-background-bg-secondary: var(--redo-colors-gray-light-mode-50);
  --redo-colors-background-bg-disabled_subtle: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-colors-text-text-brand-secondary-700: var(--redo-colors-brand-700);
  --redo-component-colors-utility-blue-utility-blue-600: var(
    --redo-colors-blue-600
  );
  --redo-component-colors-components-tooltips-tooltip-supporting-text: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-colors-background-bg-quaternary: var(
    --redo-colors-gray-light-mode-200
  );
  --redo-component-colors-components-buttons-primary-button-primary-bg: var(
    --redo-colors-gray-light-mode-900
  );
  --redo-component-colors-components-buttons-primary-button-primary-bg_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-bg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-bg_hover: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-bg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-bg_hover: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-components-buttons-primary-button-primary-fg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-primary-button-primary-border: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-component-colors-components-buttons-primary-button-primary-border_hover: var(
    --redo-colors-gray-light-mode-950
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-border_hover: var(
    --redo-colors-brand-300
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-border: var(
    --redo-colors-brand-300
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-fg: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-fg_hover: var(
    --redo-colors-gray-light-mode-800
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-fg: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-buttons-secondary-color-button-secondary-color-fg_hover: var(
    --redo-colors-brand-800
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-border: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-component-colors-components-buttons-secondary-button-secondary-border_hover: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-component-colors-components-buttons-primary-button-primary-fg_hover: var(
    --redo-colors-base-white
  );
  --redo-colors-foreground-fg-disabled_subtle: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-component-colors-utility-brand-utility-brand-600: var(
    --redo-colors-brand-600
  );
  --redo-component-colors-utility-gray-utility-gray-700: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-utility-error-utility-error-600: var(
    --redo-colors-error-600
  );
  --redo-component-colors-utility-warning-utility-warning-600: var(
    --redo-colors-warning-600
  );
  --redo-component-colors-utility-success-utility-success-600: var(
    --redo-colors-success-600
  );
  --redo-component-colors-utility-orange-utility-orange-600: var(
    --redo-colors-orange-600
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-600: var(
    --redo-colors-blue-dark-600
  );
  --redo-component-colors-utility-indigo-utility-indigo-600: var(
    --redo-colors-indigo-600
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-600: var(
    --redo-colors-fuchsia-600
  );
  --redo-component-colors-utility-pink-utility-pink-600: var(
    --redo-colors-pink-600
  );
  --redo-component-colors-utility-purple-utility-purple-600: var(
    --redo-colors-purple-600
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-600: var(
    --redo-colors-orange-dark-600
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-600: var(
    --redo-colors-blue-light-600
  );
  --redo-component-colors-utility-gray-utility-gray-600: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-component-colors-utility-brand-utility-brand-700: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-utility-error-utility-error-700: var(
    --redo-colors-error-700
  );
  --redo-component-colors-utility-warning-utility-warning-700: var(
    --redo-colors-warning-700
  );
  --redo-component-colors-utility-success-utility-success-700: var(
    --redo-colors-success-700
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-700: var(
    --redo-colors-blue-dark-700
  );
  --redo-component-colors-utility-indigo-utility-indigo-700: var(
    --redo-colors-indigo-700
  );
  --redo-component-colors-utility-purple-utility-purple-700: var(
    --redo-colors-purple-700
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-700: var(
    --redo-colors-fuchsia-700
  );
  --redo-component-colors-utility-pink-utility-pink-700: var(
    --redo-colors-pink-700
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-700: var(
    --redo-colors-orange-dark-700
  );
  --redo-component-colors-utility-orange-utility-orange-700: var(
    --redo-colors-orange-700
  );
  --redo-component-colors-utility-blue-utility-blue-700: var(
    --redo-colors-blue-700
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-700: var(
    --redo-colors-blue-light-700
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-600: var(
    --redo-colors-gray-blue-600
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-700: var(
    --redo-colors-gray-blue-700
  );
  --redo-component-colors-utility-gray-utility-gray-500: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-utility-brand-utility-brand-500: var(
    --redo-colors-brand-500
  );
  --redo-component-colors-utility-error-utility-error-500: var(
    --redo-colors-error-500
  );
  --redo-component-colors-utility-warning-utility-warning-500: var(
    --redo-colors-warning-500
  );
  --redo-component-colors-utility-success-utility-success-500: var(
    --redo-colors-success-500
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-500: var(
    --redo-colors-gray-blue-500
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-500: var(
    --redo-colors-blue-light-500
  );
  --redo-component-colors-utility-blue-utility-blue-500: var(
    --redo-colors-blue-500
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-500: var(
    --redo-colors-blue-dark-500
  );
  --redo-component-colors-utility-indigo-utility-indigo-500: var(
    --redo-colors-indigo-500
  );
  --redo-component-colors-utility-purple-utility-purple-500: var(
    --redo-colors-purple-500
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-500: var(
    --redo-colors-fuchsia-500
  );
  --redo-component-colors-utility-pink-utility-pink-500: var(
    --redo-colors-pink-500
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-500: var(
    --redo-colors-orange-dark-500
  );
  --redo-component-colors-utility-orange-utility-orange-500: var(
    --redo-colors-orange-500
  );
  --redo-component-colors-utility-gray-utility-gray-200: var(
    --redo-colors-gray-light-mode-200
  );
  --redo-component-colors-utility-brand-utility-brand-200: var(
    --redo-colors-brand-200
  );
  --redo-component-colors-utility-error-utility-error-200: var(
    --redo-colors-error-200
  );
  --redo-component-colors-utility-warning-utility-warning-200: var(
    --redo-colors-warning-200
  );
  --redo-component-colors-utility-success-utility-success-200: var(
    --redo-colors-success-200
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-200: var(
    --redo-colors-gray-blue-200
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-200: var(
    --redo-colors-blue-light-200
  );
  --redo-component-colors-utility-blue-utility-blue-200: var(
    --redo-colors-blue-200
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-200: var(
    --redo-colors-blue-dark-200
  );
  --redo-component-colors-utility-indigo-utility-indigo-200: var(
    --redo-colors-indigo-200
  );
  --redo-component-colors-utility-purple-utility-purple-200: var(
    --redo-colors-purple-200
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-200: var(
    --redo-colors-fuchsia-200
  );
  --redo-component-colors-utility-pink-utility-pink-200: var(
    --redo-colors-pink-200
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-200: var(
    --redo-colors-orange-dark-200
  );
  --redo-component-colors-utility-orange-utility-orange-200: var(
    --redo-colors-orange-200
  );
  --redo-component-colors-utility-gray-utility-gray-50: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-component-colors-utility-brand-utility-brand-50: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-utility-error-utility-error-50: var(
    --redo-colors-error-50
  );
  --redo-component-colors-utility-warning-utility-warning-50: var(
    --redo-colors-warning-50
  );
  --redo-component-colors-utility-success-utility-success-50: var(
    --redo-colors-success-50
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-50: var(
    --redo-colors-gray-blue-50
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-50: var(
    --redo-colors-blue-light-50
  );
  --redo-component-colors-utility-blue-utility-blue-50: var(
    --redo-colors-blue-50
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-50: var(
    --redo-colors-blue-dark-50
  );
  --redo-component-colors-utility-indigo-utility-indigo-50: var(
    --redo-colors-indigo-50
  );
  --redo-component-colors-utility-purple-utility-purple-50: var(
    --redo-colors-purple-50
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-50: var(
    --redo-colors-fuchsia-50
  );
  --redo-component-colors-utility-pink-utility-pink-50: var(
    --redo-colors-pink-50
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-50: var(
    --redo-colors-orange-dark-50
  );
  --redo-component-colors-utility-orange-utility-orange-50: var(
    --redo-colors-orange-50
  );
  --redo-component-colors-utility-gray-utility-gray-100: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-component-colors-utility-brand-utility-brand-100: var(
    --redo-colors-brand-100
  );
  --redo-component-colors-utility-error-utility-error-100: var(
    --redo-colors-error-100
  );
  --redo-component-colors-utility-warning-utility-warning-100: var(
    --redo-colors-warning-100
  );
  --redo-component-colors-utility-success-utility-success-100: var(
    --redo-colors-success-100
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-100: var(
    --redo-colors-gray-blue-100
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-100: var(
    --redo-colors-blue-light-100
  );
  --redo-component-colors-utility-blue-utility-blue-100: var(
    --redo-colors-blue-100
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-100: var(
    --redo-colors-blue-dark-100
  );
  --redo-component-colors-utility-indigo-utility-indigo-100: var(
    --redo-colors-indigo-100
  );
  --redo-component-colors-utility-purple-utility-purple-100: var(
    --redo-colors-purple-100
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-100: var(
    --redo-colors-fuchsia-100
  );
  --redo-component-colors-utility-pink-utility-pink-100: var(
    --redo-colors-pink-100
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-100: var(
    --redo-colors-orange-dark-100
  );
  --redo-component-colors-utility-orange-utility-orange-100: var(
    --redo-colors-orange-100
  );
  --redo-component-colors-utility-green-utility-green-800: var(
    --redo-colors-success-800
  );
  --redo-component-colors-utility-gray-utility-gray-400: var(
    --redo-colors-gray-light-mode-400
  );
  --redo-component-colors-utility-brand-utility-brand-400: var(
    --redo-colors-brand-400
  );
  --redo-component-colors-utility-error-utility-error-400: var(
    --redo-colors-error-400
  );
  --redo-component-colors-utility-warning-utility-warning-400: var(
    --redo-colors-warning-400
  );
  --redo-component-colors-utility-success-utility-success-400: var(
    --redo-colors-success-400
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-400: var(
    --redo-colors-gray-blue-400
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-400: var(
    --redo-colors-blue-light-400
  );
  --redo-component-colors-utility-blue-utility-blue-400: var(
    --redo-colors-blue-400
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-400: var(
    --redo-colors-blue-dark-400
  );
  --redo-component-colors-utility-indigo-utility-indigo-400: var(
    --redo-colors-indigo-400
  );
  --redo-component-colors-utility-purple-utility-purple-400: var(
    --redo-colors-purple-400
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-400: var(
    --redo-colors-fuchsia-400
  );
  --redo-component-colors-utility-pink-utility-pink-400: var(
    --redo-colors-pink-400
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-400: var(
    --redo-colors-orange-dark-400
  );
  --redo-component-colors-utility-orange-utility-orange-400: var(
    --redo-colors-orange-400
  );
  --redo-colors-foreground-fg-warning-secondary: var(--redo-colors-warning-500);
  --redo-colors-foreground-fg-error-secondary: var(--redo-colors-error-500);
  --redo-colors-border-border-disabled_subtle: var(
    --redo-colors-gray-light-mode-200
  );
  --redo-colors-text-text-placeholder: var(--redo-colors-gray-light-mode-500);
  --redo-colors-text-text-placeholder_subtle: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-colors-text-text-brand-tertiary-600: var(--redo-colors-brand-600);
  --redo-component-colors-components-buttons-primary-error-button-primary-error-fg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-primary-error-button-primary-error-fg_hover: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-primary-error-button-primary-error-bg: var(
    --redo-colors-error-600
  );
  --redo-component-colors-components-buttons-primary-error-button-primary-error-bg_hover: var(
    --redo-colors-error-700
  );
  --redo-component-colors-components-buttons-primary-error-button-primary-error-border: var(
    --redo-colors-error-600
  );
  --redo-component-colors-components-buttons-primary-error-button-primary-error-border_hover: var(
    --redo-colors-error-700
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-fg: var(
    --redo-colors-error-700
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-fg_hover: var(
    --redo-colors-error-800
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-bg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-bg_hover: var(
    --redo-colors-error-50
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-border: var(
    --redo-colors-error-300
  );
  --redo-component-colors-components-buttons-secondary-error-button-secondary-error-border_hover: var(
    --redo-colors-error-300
  );
  --redo-component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg: var(
    --redo-colors-gray-light-mode-400
  );
  --redo-component-colors-components-wysiwyg-editor-wysiwyg-editor-icon-fg_active: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-avatars-avatar-contrast-border: #000000;
  --redo-component-colors-components-avatars-avatar-profile-photo-border: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-avatars-avatar-bg: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-component-colors-components-avatars-avatar-focus-border: #98a2b3;
  --redo-component-colors-components-breadcrumbs-breadcrumb-fg: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-fg_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-bg_hover: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-brand-bg_hover: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-brand-fg_hover: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-icon-fg: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-icon-fg_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-breadcrumbs-breadcrumb-brand-icon-fg_hover: var(
    --redo-colors-brand-700
  );
  --redo-colors-foreground-fg-senary-300: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-colors-background-bg-primary_alt: var(
    --redo-colors-gray-light-mode-25
  );
  --redo-colors-foreground-fg-tertiary-600: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-colors-foreground-fg-tertiary_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-buttons-tertiary-button-tertiary-fg: var(
    --redo-colors-gray-light-mode-600
  );
  --redo-component-colors-components-buttons-tertiary-button-tertiary-fg_hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg_hover: var(
    --redo-colors-brand-800
  );
  --redo-component-colors-components-buttons-tertiary-button-tertiary-bg_hover: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-bg_hover: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-components-buttons-tertiary-error-button-tertiary-error-fg: var(
    --redo-colors-error-700
  );
  --redo-component-colors-components-buttons-tertiary-error-button-tertiary-error-fg_hover: var(
    --redo-colors-error-800
  );
  --redo-component-colors-components-buttons-tertiary-error-button-tertiary-error-bg_hover: var(
    --redo-colors-error-50
  );
  --redo-colors-background-bg-brand-primary_alt: var(--redo-colors-brand-50);
  --redo-colors-foreground-fg-brand-primary_alt: var(--redo-colors-brand-600);
  --redo-colors-background-bg-secondary_alt: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-component-colors-alpha-alpha-white-90: #ffffff;
  --redo-component-colors-alpha-alpha-white-80: #ffffff;
  --redo-component-colors-alpha-alpha-white-70: #ffffff;
  --redo-component-colors-alpha-alpha-white-60: #ffffff;
  --redo-component-colors-alpha-alpha-white-50: #ffffff;
  --redo-component-colors-alpha-alpha-white-40: #ffffff;
  --redo-component-colors-alpha-alpha-white-30: #ffffff;
  --redo-component-colors-alpha-alpha-white-20: #ffffff;
  --redo-component-colors-alpha-alpha-white-10: #ffffff;
  --redo-component-colors-alpha-alpha-black-10: #000000;
  --redo-component-colors-alpha-alpha-black-20: #000000;
  --redo-component-colors-alpha-alpha-black-30: #000000;
  --redo-component-colors-alpha-alpha-black-40: #000000;
  --redo-component-colors-alpha-alpha-black-50: #000000;
  --redo-component-colors-alpha-alpha-black-60: #000000;
  --redo-component-colors-alpha-alpha-black-70: #000000;
  --redo-component-colors-alpha-alpha-black-80: #000000;
  --redo-component-colors-alpha-alpha-black-90: #000000;
  --redo-colors-text-text-quarterary-500: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-colors-text-text-quinary-400: var(--redo-colors-gray-light-mode-400);
  --redo-component-colors-components-icons-featured-icons-modern-featured-icon-modern-border: var(
    --redo-colors-gray-light-mode-200
  );
  --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-brand: var(
    --redo-colors-brand-600
  );
  --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-gray: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-error: var(
    --redo-colors-error-600
  );
  --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-warning: var(
    --redo-colors-warning-600
  );
  --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-success: var(
    --redo-colors-success-600
  );
  --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-brand: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-gray: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-error: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-warning: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-success: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-application-navigation-nav-item-icon-fg: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-application-navigation-nav-item-icon-fg_active: var(
    --redo-colors-gray-light-mode-100
  );
  --redo-component-colors-components-application-navigation-nav-item-button-icon-fg: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-application-navigation-nav-item-button-icon-fg_active: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-colors-background-bg-overlay: var(--redo-colors-gray-light-mode-950);
  --redo-component-colors-utility-brand-utility-brand-50_alt: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-utility-brand-utility-brand-100_alt: var(
    --redo-colors-brand-100
  );
  --redo-component-colors-utility-brand-utility-brand-200_alt: var(
    --redo-colors-brand-200
  );
  --redo-component-colors-utility-brand-utility-brand-400_alt: var(
    --redo-colors-brand-400
  );
  --redo-component-colors-utility-brand-utility-brand-500_alt: var(
    --redo-colors-brand-500
  );
  --redo-component-colors-utility-brand-utility-brand-600_alt: var(
    --redo-colors-brand-600
  );
  --redo-component-colors-utility-brand-utility-brand-700_alt: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-sliders-slider-handle-border: var(
    --redo-colors-border-border-brand-solid
  );
  --redo-component-colors-components-sliders-slider-handle-bg: var(
    --redo-colors-base-white
  );
  --redo-component-colors-utility-brand-utility-brand-300: var(
    --redo-colors-brand-300
  );
  --redo-colors-background-bg-secondary_subtle: var(
    --redo-colors-gray-light-mode-25
  );
  --redo-component-colors-utility-gray-utility-gray-300: var(
    --redo-colors-gray-light-mode-300
  );
  --redo-component-colors-alpha-alpha-black-100: #000000;
  --redo-component-colors-alpha-alpha-white-100: #ffffff;
  --redo-component-colors-utility-brand-utility-brand-900: var(
    --redo-colors-brand-900
  );
  --redo-component-colors-utility-gray-utility-gray-900: var(
    --redo-colors-gray-light-mode-900
  );
  --redo-component-colors-components-header-sections-header-abstract-50-bg: var(
    --redo-colors-brand-50
  );
  --redo-component-colors-components-header-sections-header-abstract-100-bg: var(
    --redo-colors-brand-100
  );
  --redo-component-colors-components-header-sections-header-abstract-200-bg: var(
    --redo-colors-brand-200
  );
  --redo-component-colors-components-header-sections-header-abstract-300-bg: var(
    --redo-colors-brand-300
  );
  --redo-component-colors-utility-brand-utility-brand-800: var(
    --redo-colors-brand-800
  );
  --redo-component-colors-utility-gray-utility-gray-800: var(
    --redo-colors-gray-light-mode-800
  );
  --redo-colors-border-border-tertiary: var(--redo-colors-gray-light-mode-100);
  --redo-component-colors-components-mockups-screen-mockup-border: var(
    --redo-colors-gray-light-mode-900
  );
  --redo-colors-text-text-brand-primary-900: var(--redo-colors-brand-900);
  --redo-colors-background-bg-brand-section: var(--redo-colors-brand-800);
  --redo-colors-text-text-primary_on-brand: white;
  --redo-colors-text-text-secondary_on-brand: var(--redo-colors-brand-200);
  --redo-colors-text-text-tertiary_on-brand: var(--redo-colors-brand-200);
  --redo-colors-border-border-brand-solid_alt: var(--redo-colors-brand-600);
  --redo-colors-border-border-transparent: var(--redo-colors-base-transparent);
  --redo-component-colors-components-icons-icons-icon-fg-brand: var(
    --redo-colors-brand-600
  );
  --redo-component-colors-components-icons-icons-icon-fg-brand_on-brand: var(
    --redo-colors-brand-200
  );
  --redo-colors-background-bg-brand-section_subtle: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-footers-footer-button-fg: var(
    --redo-colors-brand-200
  );
  --redo-component-colors-components-footers-footer-button-fg_hover: var(
    --redo-colors-base-white
  );
  --redo-component-colors-components-app-store-badges-app-store-badge-border: #a6a6a6;
  --redo-colors-text-text-quarterary_on-brand: var(--redo-colors-brand-300);
  --redo-component-colors-components-footers-footer-badge-fg: var(
    --redo-colors-success-700
  );
  --redo-component-colors-components-footers-footer-badge-border: var(
    --redo-colors-success-200
  );
  --redo-component-colors-components-footers-footer-badge-bg: var(
    --redo-colors-success-50
  );
  --redo-component-colors-components-icons-social-icons-social-icon-fg-x: #242e36;
  --redo-component-colors-components-icons-social-icons-social-icon-fg-apple: var(
    --redo-colors-base-black
  );
  --redo-component-colors-components-icons-social-icons-social-icon-fg-github: var(
    --redo-colors-base-black
  );
  --redo-component-colors-components-icons-social-icons-social-icon-fg-angellist: var(
    --redo-colors-base-black
  );
  --redo-component-colors-components-icons-social-icons-social-icon-fg-tumblr: #001935;
  --redo-component-colors-components-icons-social-icons-social-icon-fg-instagram: #000100;
  --redo-colors-text-text-brand-tertiary_alt: var(--redo-colors-brand-600);
  --redo-colors-background-bg-alternate: var(--redo-colors-gray-light-mode-950);
  --redo-component-colors-components-thumbnail-thumbnail-badge-brand-fg: var(
    --redo-colors-brand-700
  );
  --redo-component-colors-components-thumbnail-thumbnail-badge-success-fg: var(
    --redo-colors-success-700
  );
  --redo-component-colors-components-toggles-toggle-button-fg_disabled: var(
    --redo-colors-gray-light-mode-50
  );
  --redo-component-colors-utility-brand-utility-brand-300_alt: var(
    --redo-colors-brand-300
  );
  --redo-component-colors-utility-brand-utility-brand-800_alt: var(
    --redo-colors-brand-800
  );
  --redo-component-colors-utility-brand-utility-brand-900_alt: var(
    --redo-colors-brand-900
  );
  --redo-component-colors-utility-error-utility-error-300: var(
    --redo-colors-error-300
  );
  --redo-component-colors-utility-warning-utility-warning-300: var(
    --redo-colors-warning-300
  );
  --redo-component-colors-utility-success-utility-success-300: var(
    --redo-colors-success-300
  );
  --redo-component-colors-utility-gray-blue-utility-gray-blue-300: var(
    --redo-colors-gray-blue-300
  );
  --redo-component-colors-utility-blue-light-utility-blue-light-300: var(
    --redo-colors-blue-light-300
  );
  --redo-component-colors-utility-blue-utility-blue-300: var(
    --redo-colors-blue-300
  );
  --redo-component-colors-utility-blue-dark-utility-blue-dark-300: var(
    --redo-colors-blue-dark-300
  );
  --redo-component-colors-utility-indigo-utility-indigo-300: var(
    --redo-colors-indigo-300
  );
  --redo-component-colors-utility-purple-utility-purple-300: var(
    --redo-colors-purple-300
  );
  --redo-component-colors-utility-fuchsia-utility-fuchsia-300: var(
    --redo-colors-fuchsia-300
  );
  --redo-component-colors-utility-pink-utility-pink-300: var(
    --redo-colors-pink-300
  );
  --redo-component-colors-utility-orange-dark-utility-orange-dark-300: var(
    --redo-colors-orange-dark-300
  );
  --redo-component-colors-utility-orange-utility-orange-300: var(
    --redo-colors-orange-300
  );
  --redo-colors-border-border-white: var(--redo-colors-base-white);
  --redo-colors-border-border-primary-hover: var(
    --redo-colors-gray-light-mode-400
  );
  --redo-colors-border-border-primary-active: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-application-navigation-nav-item-bg-zero: var(
    --redo-colors-gray-light-mode-950
  );
  --redo-component-colors-components-application-navigation-nav-item-bg-active: var(
    --redo-colors-gray-dark-mode-800
  );
  --redo-component-colors-components-application-navigation-nav-item-bg-hover: var(
    --redo-colors-gray-light-mode-700
  );
  --redo-component-colors-components-application-navigation-nav-bg: var(
    --redo-colors-background-bg-alternate
  );
  --redo-component-colors-components-support-message-input-support-internal-note-bg: var(
    --redo-colors-warning-100
  );
  --redo-component-colors-components-support-message-input-support-internal-note-stroke: var(
    --redo-colors-warning-500
  );
  --redo-colors-text-text-black: var(--redo-colors-base-black);
  --redo-component-colors-components-icons-icons-icon-white: var(
    --redo-colors-gray-light-mode-25
  );
  --redo-component-colors-components-icons-icons-icon-black: var(
    --redo-colors-gray-light-mode-900
  );
  --redo-colors-border-border-alt: var(--redo-colors-gray-light-mode-800);

  --redo-component-colors-components-buttons-primary-success-bg_hover: var(
    --redo-colors-success-50
  );

  --redo-component-colors-components-buttons-primary-bg_pressed: var(
    --redo-colors-gray-light-mode-500
  );
  --redo-component-colors-components-buttons-secondary-bg_pressed: var(
    --redo-colors-gray-light-mode-200
  );
  --redo-component-colors-components-buttons-tertiary-bg_pressed: var(
    --redo-colors-gray-light-mode-200
  );

  --redo-component-colors-components-buttons-primary-error-bg_pressed: var(
    --redo-colors-error-800
  );
  --redo-component-colors-components-buttons-secondary-error-bg_pressed: var(
    --redo-colors-error-100
  );
  --redo-component-colors-components-buttons-tertiary-error-bg_pressed: var(
    --redo-colors-error-100
  );

  --redo-component-colors-components-buttons-primary-success-bg_pressed: var(
    --redo-colors-success-800
  );
  --redo-component-colors-components-buttons-secondary-success-bg_pressed: var(
    --redo-colors-success-100
  );
  --redo-component-colors-components-buttons-tertiary-success--bg_pressed: var(
    --redo-colors-success-100
  );
  --redo-visualization-orange-primary: var(--redo-colors-orange-dark-500);
  --redo-visualization-orange-secondary: var(--redo-colors-orange-dark-300);
  --redo-visualization-blue-primary: var(--redo-colors-blue-500);
  --redo-visualization-blue-secondary: var(--redo-colors-blue-300);
}
