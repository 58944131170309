.primary {
  &.white,
  &.white-success,
  &.black {
    color: var(--redo-colors-text-text-primary-900);
    background-color: var(--redo-colors-background-bg-primary_alt);
    outline-color: var(--redo-component-colors-utility-gray-utility-gray-900);
    &.white-success.icon-wrapper {
      color: var(--redo-component-colors-utility-success-utility-success-500);
    }
    &.white.icon-wrapper {
      color: var(--redo-component-colors-utility-gray-utility-gray-500);
    }
  }
  &.success {
    color: var(--redo-component-colors-utility-success-utility-success-700);
    background-color: var(
      --redo-component-colors-utility-success-utility-success-50
    );
    outline-color: var(
      --redo-component-colors-utility-success-utility-success-200
    );
  }
  &.gray {
    color: var(--redo-component-colors-utility-gray-utility-gray-700);
    background-color: var(--redo-component-colors-utility-gray-utility-gray-50);
    outline-color: var(--redo-component-colors-utility-gray-utility-gray-200);
    &.icon-wrapper {
      color: var(--redo-component-colors-utility-gray-utility-gray-500);
    }
  }
  &.brand,
  &.purple {
    color: var(--redo-component-colors-utility-purple-utility-purple-700);
    background-color: var(
      --redo-component-colors-utility-purple-utility-purple-50
    );
    outline-color: var(
      --redo-component-colors-utility-purple-utility-purple-200
    );
  }
  &.error {
    color: var(--redo-component-colors-utility-error-utility-error-700);
    background-color: var(
      --redo-component-colors-utility-error-utility-error-50
    );
    outline-color: var(--redo-component-colors-utility-error-utility-error-200);
  }
  &.warning {
    color: var(--redo-component-colors-utility-warning-utility-warning-700);
    background-color: var(
      --redo-component-colors-utility-warning-utility-warning-50
    );
    outline-color: var(
      --redo-component-colors-utility-warning-utility-warning-200
    );
  }
  &.gray-blue {
    color: var(--redo-component-colors-utility-gray-blue-utility-gray-blue-700);
    background-color: var(
      --redo-component-colors-utility-gray-blue-utility-gray-blue-50
    );
    outline-color: var(
      --redo-component-colors-utility-gray-blue-utility-gray-blue-200
    );
  }
  &.blue-light {
    color: var(
      --redo-component-colors-utility-blue-light-utility-blue-light-700
    );
    background-color: var(
      --redo-component-colors-utility-blue-light-utility-blue-light-50
    );
    outline-color: var(
      --redo-component-colors-utility-blue-light-utility-blue-light-200
    );
  }
  &.blue {
    color: var(--redo-component-colors-utility-blue-utility-blue-700);
    background-color: var(--redo-component-colors-utility-blue-utility-blue-50);
    outline-color: var(--redo-component-colors-utility-blue-utility-blue-200);
  }
  &.indigo {
    color: var(--redo-component-colors-utility-indigo-utility-indigo-700);
    background-color: var(
      --redo-component-colors-utility-indigo-utility-indigo-50
    );
    outline-color: var(
      --redo-component-colors-utility-indigo-utility-indigo-200
    );
  }
  &.pink {
    color: var(--redo-component-colors-utility-pink-utility-pink-700);
    background-color: var(--redo-component-colors-utility-pink-utility-pink-50);
    outline-color: var(--redo-component-colors-utility-pink-utility-pink-200);
  }
  &.orange {
    color: var(--redo-component-colors-utility-orange-utility-orange-700);
    background-color: var(
      --redo-component-colors-utility-orange-utility-orange-50
    );
    outline-color: var(
      --redo-component-colors-utility-orange-utility-orange-200
    );
  }
}

.alternate {
  color: var(--redo-colors-text-text-primary-on-brand);
  &.black {
    background-color: var(--redo-colors-background-bg-alternate);
    outline-color: var(--redo-component-colors-utility-gray-utility-gray-400);
  }
  &.success {
    background-color: var(
      --redo-component-colors-utility-success-utility-success-700
    );
    outline-color: var(
      --redo-component-colors-utility-success-utility-success-400
    );
  }
  &.gray {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-500
    );
    outline-color: var(--redo-component-colors-utility-gray-utility-gray-200);
    &.icon-wrapper {
      color: var(--redo-component-colors-utility-gray-utility-gray-100);
    }
  }
  &.brand,
  &.purple {
    background-color: var(
      --redo-component-colors-utility-purple-utility-purple-600
    );
    outline-color: var(
      --redo-component-colors-utility-purple-utility-purple-400
    );
  }
  &.error {
    background-color: var(
      --redo-component-colors-utility-error-utility-error-600
    );
    outline-color: var(--redo-component-colors-utility-error-utility-error-400);
  }
  &.warning {
    color: var(--redo-colors-text-text-primary-900);
    background-color: var(
      --redo-component-colors-utility-warning-utility-warning-400
    );
    outline-color: var(
      --redo-component-colors-utility-warning-utility-warning-200
    );
  }
  &.gray-blue {
    background-color: var(
      --redo-component-colors-utility-gray-blue-utility-gray-blue-600
    );
    outline-color: var(
      --redo-component-colors-utility-gray-blue-utility-gray-blue-400
    );
  }
  &.blue-light {
    background-color: var(
      --redo-component-colors-utility-blue-light-utility-blue-light-700
    );
    outline-color: var(
      --redo-component-colors-utility-blue-light-utility-blue-light-400
    );
  }
  &.blue {
    background-color: var(
      --redo-component-colors-utility-blue-utility-blue-600
    );
    outline-color: var(--redo-component-colors-utility-blue-utility-blue-400);
  }
  &.indigo {
    background-color: var(
      --redo-component-colors-utility-indigo-utility-indigo-600
    );
    outline-color: var(
      --redo-component-colors-utility-indigo-utility-indigo-400
    );
  }
  &.pink {
    background-color: var(
      --redo-component-colors-utility-pink-utility-pink-700
    );
    outline-color: var(--redo-component-colors-utility-pink-utility-pink-400);
  }
  &.orange {
    color: var(--redo-colors-text-text-primary-900);
    background-color: var(
      --redo-component-colors-utility-orange-utility-orange-500
    );
    outline-color: var(
      --redo-component-colors-utility-orange-utility-orange-300
    );
  }
  &.white,
  &.white-success {
    color: var(--redo-colors-text-text-secondary-700);
    background-color: var(--redo-colors-background-bg-primary);
    outline-color: var(--redo-colors-border-border-primary);
    &.white-success.icon-wrapper {
      color: var(--redo-component-colors-utility-success-utility-success-500);
    }
    &.white.icon-wrapper {
      color: var(--redo-component-colors-utility-gray-utility-gray-500);
    }
  }
}
