.container {
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
}

.info-button svg {
  color: var(--redo-colors-foreground-fg-quinary-400);
}

.title {
  color: var(--redo-colors-text-text-primary-900);
}

.label {
  color: var(--redo-colors-text-text-secondary-700);
}

.label-expanded {
  color: var(--redo-colors-text-text-primary-900);
}

.price {
  color: var(--redo-colors-text-text-primary-900);
  font-weight: 500;
}

.price-expanded {
  color: var(--redo-colors-text-text-tertiary-600);
  font-weight: 400;
}
