@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400;500&display=swap");

.bar {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
}

.container {
  justify-content: stretch;
  gap: calc(var(--redo-spacing) * 2);
  flex: 1;
  padding-bottom: 16px;
}

.icon {
  display: inline-block;
  height: 1em;
  margin-top: -4px;
  vertical-align: middle;
  width: 1em;
}

.logo {
  border-radius: 50%;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  width: 32px;
  height: 32px;
  margin: -4px;
  display: inline-block;
  vertical-align: middle;
  object-fit: contain;
}

.icon-button-content {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered {
  display: flex;
  justify-content: center;
}

.flex-one {
  flex: 1;
}

.error-text {
  color: var(--redo-colors-foreground-fg-error-primary);
  outline-color: var(--redo-colors-foreground-fg-error-primary);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  font-size: var(--redo-body-small-text-size);
}

.table-header {
  font-weight: bold;
}

.table-cell {
  text-align: right;
}

.monospace {
  input {
    font-family: "Source Code Pro", monospace;
  }
}

.merchant-paid-date-range {
  display: flex;
  gap: 8px;
  align-items: center;
}

.date {
  display: flex;
  flex-direction: column;
}

.add {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;
}

.trash-icon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  color: #ff7765;
  margin-top: 16px;
}

.date-spinner {
  svg {
    height: 32px !important;
  }
}

.action-button {
  margin: 0 8px;
}

.split-status {
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
  background-color: #52b352;
  color: #52b352;
}

.main-form {
  margin-bottom: 16px;
}
