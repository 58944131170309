:root,
:host {
  --breadcrumb-font-size: 30px;
  --breadcrumb-arrow-size: 24px;
}

.breadcrumbs.small {
  --breadcrumb-font-size: 14px;
  --breadcrumb-arrow-size: 14px;
}

.breadcrumb {
  transition: color var(--redo-state-duration);

  &:last-child {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.breadcrumb-arrow {
  flex-shrink: 0;
  transform: rotate(-90deg);
  height: var(--breadcrumb-arrow-size);
  width: var(--breadcrumb-arrow-size);
  color: var(--redo-colors-foreground-fg-senary-300);
}

.breadcrumbs {
  align-items: center;
  display: flex;
  font-size: var(--breadcrumb-font-size);
  transition:
    font-size 150ms,
    gap 150ms;
}

.link:hover,
.link:focus {
  background-color: var(
    --redo-component-colors-components-breadcrumbs-breadcrumb-brand-bg_hover
  );
  border-radius: 8px;
}
