.background {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.container {
  z-index: 5000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redo-modal {
  max-width: 900px;
  height: 100%;
  align-items: center;
  justify-items: center;
  overflow: auto;
  color: var(--redo-color-text-primary);

  @media screen and (max-width: 600px) {
    width: 100vw;
  }
}

.modal-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 766px) {
    display: block;
  }
}

.modal-side-image {
  width: 200px;
  height: auto;

  @media screen and (max-width: 766px) {
    width: 100%;
    height: 140px;
    &.extended-warranty {
      display: none;
    }
  }
}

.modal-content-main {
  width: 500px;
  padding: var(--redo-spacing-3xl);
  overflow: auto;

  @media screen and (max-width: 766px) {
    width: 100%;
  }
  @media screen and (min-width: 766px) {
    &.extended-warranty {
      width: 420px;
    }
  }
}

.contentItem {
  display: flex;
  flex-direction: row;
  gap: var(--redo-spacing-md);
}

.modal-image-logo {
  height: auto;
  width: 8em;
  display: inline-block;
  color: black;
}

.modal-close-button {
  position: fixed;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.modal-logo svg {
  color: black;
  width: 6em;
  display: inline-block;
  height: auto;
  margin-bottom: 10px;
}

.modal {
  background: white;
  color: #999;
  box-shadow: 4px 4px 4px 0 rgba(64, 64, 64, 0.2);
  font-size: 14px;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  height: auto;
  border-radius: 10px;

  &.preview {
    position: relative;
    transform: none;
    top: 0;
    left: 0;
  }
}

.link {
  color: blue;
  text-decoration: underline;
}
