.text-input {
  background-color: var(--redo-colors-background-bg-primary);
  border-radius: 10px;
  padding: 8px;
  gap: 4px;
  display: flex;
  align-items: center;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  transition:
    border-color 250ms,
    outline 250ms;

  &:hover {
    border-color: var(--redo-colors-border-border-secondary);
  }

  &.no-radius {
    border-radius: 0;
    border: none;
  }

  &:not(.hide-focus):focus-within {
    border-color: var(--redo-colors-border-border-brand);
    box-shadow:
      0px 0px 0px 4px
        var(
          --redo-component-colors-components-header-sections-header-abstract-200-bg
        ),
      0px 1px 2px 0px #1018280d;
  }

  &:not(:focus-within).error {
    border-color: var(--redo-component-colors-utility-error-utility-error-400);
  }

  &.accent {
    background-color: var(--redo-primary-extra-light-color);
  }

  &.form {
    background-color: var(--redo-colors-background-bg-primary);

    &:hover {
      background-color: var(--redo-colors-background-bg-primary_hover);
    }

    &:not(.hide-focus):focus-within {
      background-color: var(--redo-colors-background-bg-primary);
    }
  }

  &.widget {
  }

  & .icon {
    margin-right: 4px;
  }

  &.no-border {
    border: none;
    padding: 4px 0;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.icon {
  line-height: 0;
}

.icon > * {
  color: var(--redo-colors-foreground-fg-quinary-400);
  width: 20px;
  height: 20px;
}

.shortHeight {
  height: 60px;
}

.mediumHeight {
  height: 100px;
}

.tallHeight {
  height: 200px;
}

.veryTallHeight {
  height: 400px;
}

.input {
  width: 100%;

  &.ellipsis {
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: var(--redo-colors-text-text-placeholder);
  }

  &.vertical {
    resize: vertical;
  }
}

textarea.input {
  line-height: 1.5;
}

.list-item {
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
}

.delete-icon {
  cursor: pointer;
  height: 16px;
  width: 16px;
}

.add {
  color: var(--redo-primary-color);
  cursor: pointer;
  margin-left: auto;
  display: flex;
  gap: 4px;
  align-items: center;
  margin-right: 24px;
}

.add-icon {
  padding-top: 4px;
}
