.input {
  width: 100%;
  background-color: var(--redo-colors-background-bg-primary);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  &.hide-border {
    border: none;
  }
}

.single-select-input {
  &.small {
    gap: 4px;
  }
  &.regular {
    gap: 8px;
  }
  composes: input;
  &:hover {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-100
    );
  }
  &:active {
    background-color: var(
      --redo-component-colors-components-buttons-secondary-bg_pressed
    );
  }
}

.tags-input {
  gap: 6px;
  composes: input;
  height: fit-content;
  &:hover:not(:has(.tag:hover)) {
    background-color: var(
      --redo-component-colors-utility-gray-utility-gray-100
    );
  }
  &:active:not(:has(.tag:active)) {
    background-color: var(
      --redo-component-colors-components-buttons-secondary-bg_pressed
    );
  }
}

.tags-container {
  height: fit-content;
}

.tag {
  &:hover {
    filter: brightness(98%);
  }
  &:active {
    filter: brightness(95%);
  }
}

.open {
  border: var(--redo-border-width) solid var(--redo-colors-gray-light-mode-600);
}

.placeholder {
  color: var(--redo-colors-gray-light-mode-500);
}

.disabled {
  cursor: none;
  background-color: var(--redo-colors-gray-light-mode-50);
  color: var(--redo-colors-gray-light-mode-500);
}

.regular {
  padding: 9px 12px;

  border-radius: 8px;
  gap: 8px;

  .chevron {
    width: 20px;
    height: 20px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.small {
  padding: 7px 10px;
  border-radius: 8px;
  gap: 8px;

  .chevron {
    width: 16px;
    height: 16px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }
}

.x-small {
  padding: 5px 8px;
  border-radius: 4px;
  gap: 4px;

  .chevron {
    width: 16px;
    height: 16px;
  }

  .icon {
    width: 12px;
    height: 12px;
  }
}

.spinner-container {
  width: 50%;
  max-width: 50px;
}
