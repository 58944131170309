/**
 * Sensible defaults
 */

@import "~modern-css-reset";

:disabled {
  pointer-events: none;
}

* {
  background: unset;
  border-spacing: unset;
  border: unset;
  box-sizing: border-box;
  color: unset;
  font-style: unset;
  font-weight: unset;
  list-style: none;
  margin: unset;
  outline: unset;
  padding: unset;
  text-align: unset;
  text-decoration: unset;
}

a,
button {
  cursor: pointer;
}

b,
strong {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
}
