.toggle-input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.toggle-switch {
  cursor: pointer;
  position: relative;
  &.xs {
    width: 28px;
    height: 16px;
    border-radius: 16px;
    &::before {
      width: 12.8px;
      height: 12.8px;
      top: 1.6px;
      left: 1.6px;
    }
  }
  &.sm {
    width: 36px;
    height: 20px;
    border-radius: 20px;
    &::before {
      width: 16px;
      height: 16px;
      top: 2px;
      left: 2px;
    }
  }
  &.md {
    width: 44px;
    height: 24px;
    border-radius: 24px;
    &::before {
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
    }
  }
  background-color: var(--redo-colors-foreground-fg-senary-300);
  transition: background-color 0.3s;
}

.toggle-switch::before {
  content: "";
  position: absolute;

  border-radius: 50%;
  background-color: var(--redo-colors-foreground-fg-on-brand);

  transition: transform 0.3s;
  box-shadow: 0 1px 3px 0 #1018281a;
}

.toggle-input:checked + .toggle-switch {
  background-color: var(--redo-colors-background-bg-alternate);
}

.toggle-input:checked + .toggle-switch {
  &.xs::before {
    transform: translateX(12px);
  }
  &.sm::before {
    transform: translateX(16px);
  }
  &.md::before {
    transform: translateX(20px);
  }
}

.toggle-input:disabled {
  + .toggle-switch::before {
    background-color: var(
      --redo-component-colors-components-toggles-toggle-button-fg_disabled
    );
  }
  + .toggle-switch {
    background-color: var(--redo-colors-background-bg-quaternary);
    cursor: unset;
  }
  &:checked + .toggle-switch {
    background-color: var(--redo-colors-foreground-fg-senary-300);
  }
}

.text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition:
    color var(--redo-input-duration),
    font-weight var(--redo-input-duration);
}
