.button {
  background-position: center;
  cursor: pointer;
  position: relative;
  border-radius: var(--border-radius-override, 10px);
  border: var(--redo-border-width) solid transparent;
  max-width: 100%;
  outline: var(--redo-active-border-width) solid transparent;
  transition:
    background var(--redo-state-duration) ease,
    border var(--redo-state-duration),
    color var(--redo-state-duration),
    outline var(--redo-state-duration);

  &:disabled {
    background: #bababa;
    color: #fff;
  }

  &:focus-visible:not(:active) {
    outline-color: var(--redo-primary-color);
    outline-width: 1px;
  }

  &.large {
    font-weight: var(--redo-font-weight-semibold);
    font-size: var(--redo-body-medium-text-size);
    padding: calc(var(--redo-spacing) * 2) calc(var(--redo-spacing) * 3);
  }

  &.medium {
    font-size: var(--redo-body-medium-text-size);
    padding: 12px 20px;
  }

  &.small {
    padding: 10px 20px;
  }

  &.extra-small {
    padding: 8px 18px;
  }

  &.micro {
    padding: 6px 10px;
  }

  &.wide-nano {
    height: 28px;
    padding: 0px 12px 0px 12px;
    border-radius: 6px;
  }

  &.nano {
    height: 28px;
    padding: 0px 4px 0px 4px;
    border-radius: 6px;
  }

  &.full-width {
    width: 100%;
  }

  &.brand {
    background-color: var(--redo-primary-color);
    color: white;

    &:disabled {
      background-color: #a393f2;
    }

    &:hover,
    &.pending {
      background-color: #715cdc;
    }

    &:active {
      transition: none;
      outline-color: rgba(95, 69, 226, 0.5);
    }
  }

  &.warning {
    background-color: var(--redo-warning-color);
    color: var(--redo-colors-base-black);

    &:disabled {
      opacity: 50%;
    }

    &:hover,
    &.pending {
      background-color: #f5a623;
    }

    &:active {
      transition: none;
      outline-color: rgba(245, 166, 35, 0.5);
    }
  }

  &.dark-theme {
    background-color: var(
      --redo-component-colors-components-application-navigation-nav-bg
    );
    color: white;

    &:disabled {
      background-color: var(--redo-colors-text-text-disabled);
    }

    &:hover {
      background-color: var(
        --redo-component-colors-components-application-navigation-nav-bg
      );
    }

    &:active {
      transition: none;
      outline-color: var(
        --redo-component-colors-components-application-navigation-nav-bg
      );
    }
  }

  &.ghost {
    border: solid var(--redo-border-width) transparent;
    background: transparent;
    color: var(
      --redo-component-colors-components-buttons-tertiary-button-tertiary-fg
    );

    &:disabled {
      color: var(--redo-colors-text-text-disabled);
    }

    &:hover {
      background-color: var(
        --redo-component-colors-components-buttons-tertiary-button-tertiary-bg_hover
      );
    }

    &:active {
      background-color: #aaa !important;
    }
  }

  &.solid-light {
    border: solid var(--redo-border-width) transparent;
    background: var(--redo-surface-dark-color);
    color: #000;

    &:disabled {
      color: var(--redo-colors-text-text-disabled);
    }

    &:hover {
      background-color: var(--redo-surface-medium-color);
      border-color: var(--redo-colors-border-border-secondary);
    }

    &:active {
      background: var(--redo-surface-dark-color);
      outline-color: rgba(24, 24, 24, 0.5);
      transition: none;
    }
  }

  &.solid-light-brand {
    border: solid var(--redo-border-width) transparent;
    background: #dad4fa;
    color: #000;

    &:disabled {
      background-color: var(--redo-surface-medium-color);
      color: var(--redo-colors-text-text-disabled);
    }

    &:hover {
      outline-color: #a393f2;
    }

    &:active {
      transition: none;
      background-color: #a393f2;
      outline-color: var(--redo-primary-color);
    }
  }

  &.danger {
    border: var(--redo-border-width) solid #e91c00;
    background: var(--redo-surface-danger-color);
    color: white;

    &:active {
      outline-color: rgb(255, 101, 80, 0.5);
    }

    &:hover {
      background: #e91c00;
    }
  }

  &.danger-outlined {
    border: var(--redo-border-width) solid var(--redo-colors-error-300);
    color: var(--redo-colors-error-600);

    &:active {
      outline-color: rgb(255, 101, 80, 0.5);
    }

    &:hover {
      background: var(--redo-colors-error-300);
      color: white;
    }
  }

  &.primary {
    border: solid var(--redo-border-width)
      var(
        --redo-component-colors-components-buttons-primary-button-primary-border
      );
    background-color: var(
      --redo-component-colors-components-buttons-primary-button-primary-bg
    );
    color: var(
      --redo-component-colors-components-buttons-primary-button-primary-fg
    );

    &.transparent {
      border-color: transparent;
    }

    &:disabled {
      opacity: 50%;
    }

    &:hover,
    &.pending {
      filter: brightness(80%);
      color: var(
        --redo-component-colors-components-buttons-primary-button-primary-fg_hover
      );
      /*background-color: var(--redo-colors-border-border-secondary);*/
    }

    &:active {
      transition: none;
      outline-color: var(--redo-colors-border-border-primary-active);
    }
  }

  &.outlined {
    background-color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-bg
    );
    color: var(--redo-colors-text-text-secondary-700);
    border: solid 1px
      var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border
      );

    &.dark {
      border-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border
      );
    }

    &.light {
      border-color: var(--redo-colors-border-border-secondary);
    }

    &.transparent {
      border-color: transparent;
    }

    &:disabled {
      opacity: 50%;
      border-color: var(--redo-colors-border-border-disabled_subtle);
      color: var(--redo-colors-text-text-disabled);
    }

    &:hover {
      background-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border_hover
      );
    }

    &:active {
      background-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-bg
      );
      outline-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border
      ) !important;
      transition: none;
    }
  }

  &.pending .content {
    opacity: 0;
  }

  &.shadow {
    box-shadow: 0px 1px 2px 0px #1018280d;
  }
}

.content {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.icon {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
  vertical-align: middle;
  &.icon-left {
    margin-right: 6px;
  }
  &.icon-right {
    margin-left: 6px;
  }
}

.icon-button {
  cursor: pointer;
  color: var(
    --redo-component-colors-components-buttons-secondary-button-secondary-fg
  );
  transition: color 250ms;
  line-height: 0;

  &.dark {
    &:disabled {
      color: var(--redo-colors-text-text-disabled);
    }

    &:not(:disabled) {
      &:hover {
        color: #6952de;
      }

      &:active {
        color: #5338d8;
        transition: none;
      }
    }
  }

  &.light {
    color: white;
  }

  &.gray {
    color: var(--redo-dark-gray);

    &:disabled {
      color: var(--redo-contrast-high-text-color);
    }

    &:not(:disabled) {
      &:hover {
        color: #6952de;
      }

      &:active {
        color: #5338d8;
        transition: none;
      }
    }
  }

  &.danger {
    color: #e91c00;
  }

  &.medium {
    padding: calc(var(--redo-spacing) * 0.5);
    & > * {
      height: var(--redo-icon-large-size);
      width: var(--redo-icon-large-size);
    }
  }

  &.small {
    & > * {
      height: var(--redo-icon-medium-size);
      width: var(--redo-icon-medium-size);
    }
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
}

.chevron {
  height: var(--redo-icon-medium-size);
  width: var(--redo-icon-medium-size);
  margin-left: auto;
  transform: rotate(-90deg);
}

.chevron-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon-button-border {
  border-radius: 100%;
  padding: 12px !important;

  &.icon-button-border-dark {
    border: 2px solid #292929;
  }
  &.icon-button-border-light {
    border: 1px solid #d6d6d6;
  }
}
