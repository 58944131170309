.offerings-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  &.regular {
    gap: 8px;
  }
  &.compact {
    gap: 4px;
  }
}

.offering-option {
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--redo-colors-background-bg-primary);
  &.regular {
    gap: 8px;
    padding: 10px;
  }
  &.compact {
    padding: 4px 8px;
  }
}

.selected-option {
  border-width: 2px;
  border-color: black;
}

.unselected-option {
  margin: 1px;
}

.offering-title {
  font-weight: bold;
  font-size: 12px;
}

.offering-subtitle {
  font-size: 12px;
  color: var(--redo-colors-gray-true-600);
}

.offering-price {
  font-size: 14px;
  font-weight: bold;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.subtitle {
  font-size: 12px;
  font-weight: normal;
  margin-top: -4px;
}

.shield-icon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
}

.shield-icon-compact {
  width: 18px;
  height: 18px;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  width: fit-content;
  height: fit-content;
}

.add-button:hover {
  background-color: var(--redo-colors-gray-light-mode-50);
}

.add-button:active {
  background-color: var(
    --redo-component-colors-components-buttons-secondary-bg_pressed
  );
}

.bullets li {
  list-style: disc;
  margin-left: var(--redo-spacing-xl);
  font-size: var(--redo-body-medium-text-size);
  margin-bottom: -4px;
}

.subtitle {
  color: var(--redo-colors-text-text-tertiary-600);
}

.extended-warranty-modal {
  p {
    line-height: 24px;
  }
}

.learn-more {
  font-size: 12px;
  font-weight: bold;
  color: var(--redo-component-colors-utility-blue-dark-utility-blue-dark-500);
  cursor: pointer;
  text-decoration: none;
}

.see-details-link {
  color: var(--redo-colors-text-text-tertiary-600);
  cursor: pointer;
  text-decoration: underline;
}

.see-details-icon {
  width: 12px;
  height: 12px;
}

.container {
  width: max-content;
}

.remove {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-color-text-tertiary);
  line-height: 18px;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  width: fit-content;
}

.add-button:hover {
  background-color: var(--redo-component-colors-utility-gray-utility-gray-100);
}

.add-button:active {
  background-color: var(
    --redo-component-colors-components-buttons-secondary-bg_pressed
  );
}

.learn {
  font-size: var(--redo-body-xsmall-text-size);
  color: var(--redo-colors-blue-dark-500);
  line-height: 18px;
  text-decoration: none;
}

.plan {
  line-height: 18px !important;
}

.pdp-tiles-background {
  background-color: var(--redo-colors-background-bg-tertiary);
}
