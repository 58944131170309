.list-item-container {
  transition: background-color 300ms;

  &.focused {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }

  &.small {
    font-size: var(--redo-body-xsmall-text-size);
    height: 24px;
    max-height: 24px;
    min-height: 24px;
  }
  &.medium {
    height: 40px;
    max-height: 40px;
    min-height: 40px;
  }
  &.large {
    height: 60px;
    max-height: 60px;
    min-height: 60px;
  }
  cursor: pointer;
}

.children-container {
  overflow: hidden;
  width: 100%;
}

.right-area {
  width: 36px;
}

.icon-wrapper {
  width: 100%;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.disabled-children {
  cursor: not-allowed;
}

.container {
  transition: background-color 200ms;

  &.card {
    outline-width: 1px;
    outline-style: solid;
    outline-offset: -1px;
    outline-color: var(--redo-colors-border-border-primary);
  }

  cursor: pointer;

  border-radius: 4px;

  &.focused,
  &:hover {
    background-color: var(--redo-colors-background-bg-primary_hover);
  }

  &.selected {
    &:not(.disabled) {
      background-color: var(--redo-colors-background-bg-primary_active);
      &:active {
        background-color: var(--redo-colors-background-bg-quaternary);
      }
    }
  }

  &.disabled {
    cursor: unset;
    pointer-events: none;
  }
}

.menu {
  > svg {
    color: var(--redo-colors-foreground-fg-quarterary-500);
    height: 16px;
    width: 16px;
  }
  border-radius: 4px;
  align-items: center;
  display: flex;
  padding: 0 2px 0 2px;

  &:hover {
    background-color: var(--redo-colors-gray-true-200);
    &:active {
      background-color: var(--redo-colors-gray-true-300);
    }
  }
}

.icon {
  color: var(--redo-colors-foreground-fg-quarterary-500);
  min-width: max-content;
  &.sm {
    width: 12px;
    height: 12px;
  }
  &.md,
  &.lg {
    width: 20px;
    height: 20px;
  }
}

.checkmark {
  color: var(--redo-colors-foreground-fg-primary-900);
  &.sm {
    width: 16px;
    height: 16px;
  }
  &.md,
  &.lg {
    width: 20px;
    height: 20px;
  }
}

.text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition:
    color 200ms,
    font-weight 200ms;
}

.status-dot-container {
  display: flex;
  align-items: center;
  justify-content: center;
  &.sm {
    width: 12px;
    height: 12px;
  }
  &.md,
  &.lg {
    width: 20px;
    height: 20px;
  }
}

.status-dot {
  border-radius: 50%;
  &.sm,
  &.md {
    width: 8px;
    height: 8px;
  }
  &.lg {
    width: 10px;
    height: 10px;
  }

  &.green {
    background-color: var(--redo-colors-foreground-fg-success-secondary);
  }
  &.red {
    background-color: var(--redo-colors-foreground-fg-error-secondary);
  }
  &.yellow {
    background-color: var(--redo-colors-foreground-fg-warning-secondary);
  }
  &.gray {
    background-color: var(--redo-colors-foreground-fg-disabled);
  }
}

.image-circle {
  display: inline-block;
  vertical-align: middle;
  object-fit: contain;
  border-radius: 50%;
  &.sm {
    width: 16px;
    height: 16px;
  }
  &.md,
  &.lg {
    width: 24px;
    height: 24px;
  }
}
