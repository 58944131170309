.arbiter-badge-container {
  max-width: min-content;
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -1px;
  overflow: hidden;

  border-radius: 4px;
  &.xs {
    border-radius: 2px;
  }
}

.icon-button {
  color: inherit;
}

.icon-wrapper {
  svg {
    height: 12px !important;
    width: 12px !important;
  }

  &.xs {
    svg {
      height: 8px !important;
      width: 8px !important;
    }
  }
}

.fill-width {
  width: 100%;
}

.color-swatch {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: solid 1px var(--redo-border-color-dark);
}

.shadow {
  box-shadow: 0px 1px 2px 0px #1018280d;
}
