:root,
:host {
  /* DEPRECATED */
  --redo-active-border-width: 2px;
  --redo-border-width: 1px;
  --redo-corner-xlarge-radius: 24px;
  --redo-corner-large-radius: 16px;
  --redo-corner-medium-radius: 12px;
  --redo-corner-small-radius: 8px;
  --redo-corner-xsmall-radius: 4px;
  --redo-xsmall-radius: 4px;
}

/* spacing unit is defined in spacing.css */
.radius-none {
  border-radius: var(--redo-spacing-none);
}

.radius-xxs {
  border-radius: var(--redo-spacing-xxs);
}

.radius-xs {
  border-radius: var(--redo-spacing-xs);
}

.radius-sm {
  border-radius: var(--redo-spacing-sm);
}

.radius-md {
  border-radius: var(--redo-spacing-md);
}

/* from here, the values are not consistent with spacing.css, but that is by design */
.radius-lg {
  border-radius: calc(var(--redo-spacing-unit) * 2.5);
}

.radius-xl {
  border-radius: var(--redo-spacing-lg);
}

.radius-2xl {
  border-radius: var(--redo-spacing-xl);
}

.radius-3xl {
  border-radius: var(--redo-spacing-2xl);
}

.radius-4xl {
  border-radius: var(--redo-spacing-3xl);
}

.radius-full {
  border-radius: 9999px;
}

/* spacing unit is defined in spacing.css */
.radius-bottom-none {
  border-bottom-left-radius: var(--redo-spacing-none);
  border-bottom-right-radius: var(--redo-spacing-none);
}

.radius-bottom-xxs {
  border-bottom-left-radius: var(--redo-spacing-xxs);
  border-bottom-right-radius: var(--redo-spacing-xxs);
}

.radius-bottom-xs {
  border-bottom-left-radius: var(--redo-spacing-xs);
  border-bottom-right-radius: var(--redo-spacing-xs);
}

.radius-bottom-sm {
  border-bottom-left-radius: var(--redo-spacing-sm);
  border-bottom-right-radius: var(--redo-spacing-sm);
}

.radius-bottom-md {
  border-bottom-left-radius: var(--redo-spacing-md);
  border-bottom-right-radius: var(--redo-spacing-md);
}

/* from here, the values are not consistent with spacing.css, but that is by design */
.radius-bottom-lg {
  border-bottom-left-radius: calc(var(--redo-spacing-unit) * 2.5);
  border-bottom-right-radius: calc(var(--redo-spacing-unit) * 2.5);
}

.radius-bottom-xl {
  border-bottom-left-radius: var(--redo-spacing-lg);
  border-bottom-right-radius: var(--redo-spacing-lg);
}

.radius-bottom-2xl {
  border-bottom-left-radius: var(--redo-spacing-xl);
  border-bottom-right-radius: var(--redo-spacing-xl);
}

.radius-bottom-3xl {
  border-bottom-left-radius: var(--redo-spacing-2xl);
  border-bottom-right-radius: var(--redo-spacing-2xl);
}

.radius-bottom-4xl {
  border-bottom-left-radius: var(--redo-spacing-3xl);
  border-bottom-right-radius: var(--redo-spacing-3xl);
}

.radius-bottom-full {
  border-bottom-left-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.border-color-primary {
  border-color: var(--redo-colors-border-border-primary);
}

.border-color-secondary {
  border-color: var(--redo-colors-border-border-secondary);
}

.border-color-tertiary {
  border-color: var(--redo-colors-border-border-tertiary);
}

.border-color-disabled {
  border-color: var(--redo-colors-border-border-disabled);
}

.border-color-disabled_subtle {
  border-color: var(--redo-colors-border-border-disabled_subtle);
}

.border-color-transparent {
  border-color: var(--redo-colors-border-border-transparent);
}

.border-color-brand {
  border-color: var(--redo-colors-border-border-brand);
}

.border-color-brand-solid {
  border-color: var(--redo-colors-border-border-brand-solid);
}

.border-color-error {
  border-color: var(--redo-colors-border-border-error);
}

.border-color-error-solid {
  border-color: var(--redo-colors-border-border-error-solid);
}

/* This class should come before the border-width classes for priority */
.border-style-solid {
  border-style: solid;
  border-width: 0;
}

.border-style-dashed {
  border-style: dashed;
}

.border-width-none {
  border-width: 0;
}

.border-width-1px {
  border-width: 1px;
}

.border-width-2px {
  border-width: 2px;
}

.border-top-width-none {
  border-top-width: 0;
}

.border-top-width-1px {
  border-top-width: 1px;
}

.border-top-width-2px {
  border-top-width: 2px;
}

.border-right-width-none {
  border-right-width: 0;
}

.border-right-width-1px {
  border-right-width: 1px;
}

.border-right-width-2px {
  border-right-width: 2px;
}

.border-bottom-width-none {
  border-bottom-width: 0;
}

.border-bottom-width-1px {
  border-bottom-width: 1px;
}

.border-bottom-width-2px {
  border-bottom-width: 2px;
}

.border-style-none {
  border-style: none;
}

.border-left-width-none {
  border-left-width: 0;
}

.border-left-width-1px {
  border-left-width: 1px;
}

.border-left-width-2px {
  border-left-width: 2px;
}
