.preview-container {
  background-color: var(--redo-colors-background-bg-quaternary);
  border-radius: var(--redo-corner-medium-radius);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.pdp-preview-container {
  background-color: var(--redo-colors-background-bg-primary);
  box-shadow: 4px 4px 4px 0 rgba(64, 64, 64, 0.2);
  border-radius: var(--redo-corner-medium-radius);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  min-width: 500px;
}

.toggle-preview-container {
  background-color: var(--redo-colors-background-bg-primary);
  box-shadow: 4px 4px 4px 0 rgba(64, 64, 64, 0.2);
  border-radius: var(--redo-corner-medium-radius);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
}

.full-grid {
  width: 100%;
}
