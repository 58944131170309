.icon {
  --large-icon-size: 56px;
  --medium-icon-size: 48px;
  --compact-icon-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.light {
    color: var(
      --redo-component-colors-components-icons-featured-icons-modern-featured-icon-modern-border
    );
  }

  &.success {
    color: var(--redo-component-colors-utility-success-utility-success-100);
    background-color: var(
      --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-success
    );
  }
  &.large {
    width: var(--large-icon-size);
    height: var(--large-icon-size);
  }

  &.medium {
    width: var(--medium-icon-size);
    height: var(--medium-icon-size);
  }

  &.compact {
    width: var(--compact-icon-size);
    height: var(--compact-icon-size);
  }

  &.primary-light {
    &.brand {
      color: var(
        --redo-component-colors-components-icons-featured-icons-light-featured-icon-light-fg-brand
      );
      background-color: var(--redo-colors-background-bg-brand-secondary);
    }
  }

  &.primary-dark {
    &.brand {
      color: var(
        --redo-component-colors-components-icons-featured-icons-dark-featured-icon-dark-fg-brand
      );
      background-color: var(--redo-component-colors-bg-brand-solid);
    }
  }

  &.outline {
    border-width: 1px;
    border-style: solid;

    border-color: var(
      --redo-component-colors-components-icons-featured-icons-modern-featured-icon-modern-border
    );
    border-radius: 12px;
    &.large {
      border-radius: 12px;
    }
    &.medium {
      border-radius: 10px;
    }
    &.compact {
      border-radius: 8px;
    }
    &.extra-small {
      border-radius: 6px;
    }
  }

  &.primary-modern,
  .primary-dark {
    border-radius: 12px;
    &.large {
      border-radius: 12px;
    }
    &.medium {
      border-radius: 10px;
    }
    &.compact {
      border-radius: 8px;
    }
    &.extra-small {
      border-radius: 6px;
    }
  }

  &.primary-light {
    border-radius: 50%;
  }
}
