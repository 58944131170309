.flex {
  display: flex;
}

.row {
  flex-direction: row;

  &.reverse {
    flex-direction: row-reverse;
  }
}
.column {
  flex-direction: column;

  &.reverse {
    flex-direction: column-reverse;
  }
}

.align-center {
  align-items: center;
}
.align-flex-start {
  align-items: flex-start;
}
.align-flex-end {
  align-items: flex-end;
}
.align-stretch {
  align-items: stretch;
}
.align-baseline {
  align-items: baseline;
}

.align-self-center {
  align-self: center;
}
.align-self-flex-start {
  align-self: flex-start;
}
.align-self-flex-end {
  align-self: flex-end;
}
.align-self-stretch {
  align-self: stretch;
}
.align-self-baseline {
  align-self: baseline;
}

.justify-center {
  justify-content: center;
}
.justify-flex-start {
  justify-content: flex-start;
}
.justify-flex-end {
  justify-content: flex-end;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-evenly {
  justify-content: space-evenly;
}

.wrap-wrap {
  flex-wrap: wrap;
}

.wrap-nowrap {
  flex-wrap: nowrap;
}

.wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}
