.border {
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 12px;
  padding: 16px;
}

.border-selected {
  border: 2px solid var(--redo-colors-background-bg-alternate);
  border-radius: 12px;
  padding: 15px;
}

.circle {
  --size: 16px;
  border-radius: 50%;
  height: var(--size);
  width: var(--size);
  border: var(--redo-border-width) solid var(--redo-colors-text-text-disabled);
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background 300ms,
    border 300ms;
}

.description {
  margin-left: 24px;
  margin-bottom: 4px;
}

.circle-inner {
  width: calc(50% + var(--redo-border-width));
  height: calc(50% + var(--redo-border-width));
  border-radius: 50%;
}

.input {
  cursor: inherit;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.input:checked ~ .circle {
  border-color: var((--redo-colors-background-bg-alternate));
}

.input:checked ~ * .circle-inner {
  background: var(--redo-colors-background-bg-alternate);
}

.input:not(:checked) ~ * .circle-inner {
  display: none;
}

.input:checked ~ * .circle-inner {
  animation: var(--redo-input-duration) ease check;
}

.input:not(:checked) ~ * .circle-inner {
  animation: var(--redo-input-duration) ease uncheck;
  opacity: 0;
}

.circle-inner-disabled {
  width: calc(50% + var(--redo-border-width));
  height: calc(50% + var(--redo-border-width));
  border-radius: 50%;
}

.input:checked ~ * .circle-inner-disabled {
  background: var(--redo-light-gray);
}

.input:not(:checked) ~ * .circle-inner-disabled {
  display: none;
}

.input:checked ~ * .circle-inner-disabled {
  animation: var(--redo-input-duration) ease check;
}

.input:not(:checked) ~ * .circle-inner-disabled {
  animation: var(--redo-input-duration) ease uncheck;
  opacity: 0;
}

.label {
  flex: 1;
  font-size: var(--redo-body-small-text-size);
  line-height: 1;
}

.radio-group {
  display: flex;
  gap: 12px;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }

  .radio-button {
    gap: var(--redo-spacing);
  }
}

.radio-button {
  align-items: center;
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  position: relative;
  cursor: pointer;
}

.radio-button-no-center {
  display: flex;
  gap: calc(var(--redo-spacing) * 2);
  position: relative;
  cursor: pointer;
}

.right-radio {
  flex-direction: row-reverse;
}

.radio-button:hover .circle {
  background-color: var(--redo-colors-border-border-secondary);
}

.subcontent {
  padding-top: 8px;
}

@keyframes check {
  from {
    opacity: 0;
    scale: 0;
  }

  80% {
    opacity: 1;
    scale: 1.1;
  }
}

@keyframes uncheck {
  from {
    opacity: 1;
  }
}
