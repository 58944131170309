.actions {
  transition: opacity 250ms ease;
  padding-right: 16px;
  &.border {
    border-right: 1px solid #ccc;
  }
}

.actions.collapse {
  opacity: 0;
}

.tabs-wrapper:empty,
.actions-container:empty {
  display: none;
}

.actions-container {
  min-width: fit-content;
}

.page-padding {
  padding: 24px 48px;
}

.content {
  flex: 1;
  /**
   * These values should be kept in sync with theme/misc.css
   */
  padding: 24px 48px 32px 48px;
  width: 100%;
  min-height: 0;

  &.hide-padding {
    padding: 0;
  }

  &.fullscreen {
    padding: 0;
  }

  &.opt-out-top-padding {
    padding-top: 0;
  }
  overflow-y: auto;
}

.content-child {
  /**
   * These values should be kept in sync with theme/misc.css
   */
  left: 48px !important;
  right: 48px !important;
  min-width: 0;
  &.child-full-height {
    height: 100%;
  }
}

.no-scroll-x {
  min-width: unset;
  width: 100%;
}

.no-scroll-y {
  height: 100%;
}

.profile {
  display: flex;
  align-items: center;
  gap: var(--redo-spacing);
  line-height: 1;
}

.profile-container {
  position: relative;
}

.profile-image {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  object-fit: contain;
  background: white;
  overflow: hidden;
}

.profile-image > * {
  flex: 1;
}

.profile-main {
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 6px;
}

.profile-title {
  font-family: var(--redo-title-font-family);
  font-size: var(--redo-body-large-text-size);
  color: #181818;
}

.profile-subtitle {
  font-size: var(--redo-body-small-text-size);
  color: #181818;
  opacity: 0.7;
}

.title {
  margin-right: auto;
  min-width: 0;
}

@media screen and (max-width: 600px) {
  .content {
    min-width: 900px;
  }
}
