/**
 * Redo fonts
 */

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@font-face {
  font-family: "Maison Neue Extended";
  src: url("fonts/MaisonNeueExtended-Bold.woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Maison Neue Extended";
  src: url("fonts/MaisonNeueExtended-Book.woff2");
  font-weight: light;
}

@font-face {
  font-family: "Maison Neue Extended";
  src: url("fonts/MaisonNeueExtended-Medium.woff2");
}
