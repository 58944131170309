.input {
  height: 0;
  position: absolute;
  opacity: 0;
  cursor: inherit;
  width: 0;
}

.box {
  &.small {
    height: 16px;
    width: 16px;
  }
  &.medium {
    height: 20px;
    width: 20px;
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  transition:
    background-color var(--redo-input-duration),
    border-color var(--redo-input-duration);

  border-radius: var(--redo-xsmall-radius);
  border-width: 1px;
  border-style: solid;
  border-color: var(--redo-colors-border-border-primary);
  &.disabled {
    border-color: var(--redo-colors-border-border-disabled);
  }

  &.checked:not(.disabled) {
    background-color: var(--redo-colors-background-bg-secondary-solid);
  }
  &.checked {
    background-color: var(--redo-colors-background-bg-alternate);
  }
  &.disabled {
    background-color: var(--redo-colors-background-bg-disabled_subtle);
  }
}

.icon {
  &.small {
    height: 12px;
    width: 12px;
  }
  &.medium {
    height: 14px;
    width: 14px;
  }

  color: var(--redo-colors-foreground-fg-on-brand);
  &.disabled {
    color: var(--redo-colors-foreground-fg-disabled_subtle);
  }

  transition: opacity var(--redo-input-duration);
  opacity: 1;
  &:not(.checked) {
    opacity: 0;
  }
}

.text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition:
    color var(--redo-input-duration),
    font-weight var(--redo-input-duration);
}

.label-spacer {
  &.small {
    height: var(--redo-body-small-line-height);
  }
  &.medium {
    height: var(--redo-body-medium-line-height);
  }
}

.description-spacer {
  height: var(--redo-body-small-line-height);
}
