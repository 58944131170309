.saving-indicator {
  width: 16px;
  height: 16px;
  animation: spin 1.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.saved-indicator {
  opacity: 0;
  animation: fadeInOut 2.5s linear forwards;

  .icon {
    width: 16px;
    height: 16px;
    color: var(--redo-colors-success-600);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
