.link {
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
  color: var(--redo-colors-text-text-primary-900);

  &.contrast-low {
    color: var(--redo-colors-text-text-disabled);
  }
}

.link:hover {
  color: #6952de;
}

.external-link {
  align-items: center;
  color: var(
    --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg
  );
  cursor: pointer;
  display: inline-flex;
  gap: 0.5em;
  text-decoration: underline;
  text-decoration-color: transparent;
  text-underline-position: under;
  transition:
    color var(--redo-state-duration),
    text-decoration-color var(--redo-state-duration);

  &:hover {
    color: var(
      --redo-component-colors-components-buttons-tertiary-color-button-tertiary-color-fg_hover
    );
    text-decoration-color: currentcolor;
  }
}

.external-link-icon {
  height: 1em;
  width: 1em;
}

.link-label {
  flex-shrink: 1;
}
