.container {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  font-size: var(--redo-body-small-text-size);
}

:global(.MuiPopper-root) {
  z-index: 1500;
}

.select {
  color: var(--redo-colors-text-text-primary-900);
  background-color: var(--redo-colors-background-bg-primary);
  border-radius: 10px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  display: flex;
  justify-content: space-between;
  padding: 12px;
  cursor: pointer;
  align-items: center;
  font-size: var(--redo-body-medium-text-size);
  transition: border var(--redo-state-duration);
  gap: var(--redo-spacing);
  line-height: 100%;

  &.reduced-padding {
    padding: 8px 12px;
  }

  &.brand {
    background-color: var(--redo-colors-background-bg-primary);
    border: var(--redo-border-width) solid
      var(--redo-colors-border-border-primary);
  }

  &:hover {
    border-color: var(--redo-colors-border-border-primary_hover);
  }

  &:disabled {
    text-decoration: line-through;
    color: var(--redo-colors-text-text-disabled);
  }
}

.dropdown {
  transition: opacity 250ms;
  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);
  margin: var(--redo-spacing);
}

.dropdown.background {
  background-color: var(--redo-colors-background-bg-primary);
}

.dropdown.enter,
.dropdown.exit-active {
  /* opacity: 0; */
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}

.check {
  --size: 16px;
  color: var(--redo-colors-foreground-fg-brand-primary-600);
  height: var(--size);
  width: var(--size);
}

.select-icon {
  color: var(--redo-colors-foreground-fg-quarterary-500);
  width: var(--redo-icon-medium-size);
  height: var(--redo-icon-medium-size);
  transition: color var(--redo-state-duration);
  flex-shrink: 0;
}

.line {
  border: 1px solid var(--redo-colors-border-border-secondary);
}
