.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

:root,
:host {
  /**
   * These values should be kept in sync with page.module.css
   * .content and .content-child, and side-nav.module.css
   * .nav.
   * (really, they should just replace them, but I didn't want to break things on accident)
   */
  --redo-web-page-content-padding-top: 24px;
  --redo-web-page-content-padding-left: 48px;
  --redo-web-page-content-padding-right: 48px;
  --redo-web-page-content-padding-bottom: 32px;
  --redo-web-page-side-nav-width: 240px;
}
