.container {
  position: relative;
  box-shadow: 0 12px 16px -4px #10182814;
}

.content-container {
  &.desktop {
    width: 400px;
  }
  &.mobile {
    width: 343px;
  }
  &.with-image {
    width: 343px;
  }
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.fullModalImage {
  height: 200px;
  width: auto;
  object-fit: cover;
}
