.checkbox-card {
  width: 100%;
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 12px;
  cursor: pointer;
  transition: border-color var(--redo-state-duration) ease !important;
  &.checkbox-card-selected {
    border-color: var(--redo-colors-text-text-secondary_hover);
  }
}

.outer-border:hover {
  border-color: var(--redo-colors-text-text-secondary_hover);
}

.outer-border {
  border: 1px solid var(--redo-colors-border-border-secondary);
  transition: border-color var(--redo-state-duration) ease !important;
  border-radius: 13px;
  &.checkbox-card-selected {
    border-color: var(--redo-colors-text-text-secondary_hover);
  }
}

.card-icon {
  width: 32px;
  height: 32px;
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
