.measure-box {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  > * {
    white-space: nowrap;
    overflow: visible;
  }
}
