.w-none {
  width: 0;
}

.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-fit-content {
  width: fit-content;
}

.w-xxs {
  width: 20rem;
}

.w-xs {
  width: 24rem;
}

.w-sm {
  width: 30rem;
}

.w-md {
  width: 35rem;
}

.w-lg {
  width: 40rem;
}

.w-xl {
  width: 48rem;
}

.w-2xl {
  width: 64rem;
}

.w-3xl {
  width: 80rem;
}

.w-4xl {
  width: 90rem;
}

.w-5xl {
  width: 100rem;
}

.w-6xl {
  width: 120rem;
}

.min-w-none {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.min-w-xxs {
  min-width: 20rem;
}

.min-w-xs {
  min-width: 24rem;
}

.min-w-sm {
  min-width: 30rem;
}

.min-w-md {
  min-width: 35rem;
}

.min-w-lg {
  min-width: 40rem;
}

.min-w-xl {
  min-width: 48rem;
}

.min-w-2xl {
  min-width: 64rem;
}

.min-w-3xl {
  min-width: 80rem;
}

.min-w-4xl {
  min-width: 90rem;
}

.min-w-5xl {
  min-width: 100rem;
}

.min-w-6xl {
  min-width: 120rem;
}

.max-w-none {
  max-width: 0;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xxs {
  max-width: 20rem;
}

.max-w-xs {
  max-width: 24rem;
}

.max-w-sm {
  max-width: 30rem;
}

.max-w-md {
  max-width: 35rem;
}

.max-w-lg {
  max-width: 40rem;
}

.max-w-xl {
  max-width: 48rem;
}

.max-w-2xl {
  max-width: 64rem;
}

.max-w-3xl {
  max-width: 80rem;
}

.max-w-4xl {
  max-width: 90rem;
}

.max-w-5xl {
  max-width: 100rem;
}

.max-w-6xl {
  max-width: 120rem;
}
