.radio-button {
  cursor: pointer;
  &.disabled {
    cursor: unset;
  }
}

.radio-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: inherit;
}

.text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition:
    color var(--redo-input-duration),
    font-weight var(--redo-input-duration);
}

.circle {
  display: flex;
  border-radius: 50%;
  transition:
    background 0.3s ease,
    box-shadow 0.1s ease;

  .circle-inner {
    border-radius: 50%;
  }

  &.xs {
    height: 14px;
    width: 14px;
    > .circle-inner {
      width: 5.6px;
      height: 5.6px;
    }
  }
  &.sm {
    height: 16px;
    width: 16px;
    > .circle-inner {
      width: 6px;
      height: 6px;
    }
  }
  &.md {
    height: 20px;
    width: 20px;
    > .circle-inner {
      width: 8px;
      height: 8px;
    }
  }

  &:not(.selected) {
    border: 1px solid var(--redo-colors-border-border-primary);
  }

  &.selected {
    background: var(--redo-colors-background-bg-alternate);
    > .circle-inner {
      background: var(--redo-colors-foreground-fg-on-brand);
    }

    &:hover {
      background-color: var(--redo-colors-background-bg-secondary-solid);
    }

    &.disabled {
      border: 1px solid var(--redo-colors-border-border-disabled);
      > .circle-inner {
        background: var(--redo-colors-foreground-fg-disabled_subtle);
      }
    }
  }

  &.disabled {
    background: var(--redo-colors-background-bg-disabled_subtle);
  }

  &.focused {
    box-shadow: 0 0 0 4px #5252523d;
  }
}

.border {
  border: 1px solid var(--redo-colors-border-border-secondary);
  border-radius: 12px;
  padding: 16px;
  &.selected {
    border: 2px solid var(--redo-colors-background-bg-alternate);
    padding: 15px;
  }
}
