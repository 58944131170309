.rate-table {
  width: 100%;
  border-collapse: collapse;
}

.rate-table thead th {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  text-align: center;
}

.rate-table tbody td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 3px;
}

.rate-table tr td:first-child,
.rate-table tr th:first-child {
  background-color: #f9f9f9;
}
