:root,
:host {
  /* DEPRECATED */
  --redo-body-font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  --redo-body-large-text-size: 18px;
  --redo-body-medium-text-size: 16px;
  --redo-body-medium-line-height: 24px;
  --redo-body-small-text-size: 14px;
  --redo-body-small-line-height: 20px;
  --redo-body-xsmall-text-size: 12px;
  --redo-font-weight-semibold: 600;
  --redo-line-height: 1.5;
  --redo-title-font-family: "Maison Neue Extended", sans-serif;
  --redo-title-large-text-size: 20px;
  --redo-title-medium-text-size: 16px;
  --redo-header-size-md: 36px;
}

/* Sizes */

.text-xxs {
  font-size: 10px;
  line-height: 12px;
}

.text-xs {
  font-size: 12px;
  line-height: 16px;
}

.text-sm {
  font-size: 14px;
  line-height: 20px;
}

.text-md {
  font-size: 16px;
  line-height: 24px;
}

.text-lg {
  font-size: 18px;
  line-height: 28px;
}

.text-xl {
  font-size: 20px;
  line-height: 30px;
}

.text-2xl {
  font-size: 24px;
  line-height: 32px;
}

.header-xxs {
  font-size: 18px;
}

.header-xs {
  font-size: 24px;
  line-height: 32px;
}

.header-sm {
  font-size: 30px;
  line-height: 38px;
}

.header-md {
  font-size: 36px;
  line-height: 44px;
}

.header-lg {
  font-size: 48px;
  line-height: 60px;
}

.header-xl {
  font-size: 60px;
  line-height: 72px;
}

.header-2xl {
  font-size: 72px;
  line-height: 90px;
}

.weight-thin {
  font-weight: 300;
}

.weight-regular {
  font-weight: 400;
}

.weight-medium {
  font-weight: 500;
}

.weight-semibold {
  font-weight: 600;
}

.weight-bold {
  font-weight: 700;
}

/* Colors */

.text-color-primary {
  color: var(--redo-colors-text-text-primary-900);
}

.text-color-secondary {
  color: var(--redo-colors-text-text-secondary-700);
}

.text-color-tertiary {
  color: var(--redo-colors-text-text-tertiary-600);
}

.text-color-quaternary {
  color: var(--redo-colors-text-text-quarterary-500);
}

.text-color-quinary {
  color: var(--redo-colors-text-text-quinary-400);
}

.text-color-disabled {
  color: var(--redo-colors-text-text-disabled);
}

.text-color-placeholder {
  color: var(--redo-colors-text-text-placeholder);
}

.text-color-primary-on-brand {
  color: var(--redo-colors-base-white);
}

.text-color-action-primary {
  color: var(--redo-component-colors-fg-action-primary);
}

.text-color-brand-primary {
  color: var(--redo-colors-text-text-brand-primary-900);
}

.text-color-brand-secondary {
  color: var(--redo-colors-text-text-brand-secondary-700);
}

.text-color-brand-tertiary {
  color: var(--redo-colors-text-text-brand-tertiary-600);
}

.text-color-error {
  color: var(--redo-colors-text-text-error-primary-600);
}

.text-color-warning {
  color: var(--redo-colors-text-text-warning-primary-600);
}

.text-color-success {
  color: var(--redo-colors-text-text-success-primary-600);
}

.text-color-white {
  color: var(--redo-colors-text-text-white);
}

.text-color-tooltip-supporting-text {
  color: var(
    --redo-component-colors-components-tooltips-tooltip-supporting-text
  );
}

.text-color-tooltip-calculation-text {
  color: var(--redo-colors-moss-200);
}

.hover-text-color-primary:hover {
  color: var(--redo-colors-text-text-primary-900);
}

.hover-text-color-secondary:hover {
  color: var(--redo-colors-text-text-secondary-700);
}

.hover-text-color-tertiary:hover {
  color: var(--redo-colors-text-text-tertiary-600);
}

.hover-text-color-quaternary:hover {
  color: var(--redo-colors-text-text-quaternary-500);
}

.hover-text-color-disabled:hover {
  color: var(--redo-colors-text-text-disabled);
}

.hover-text-color-placeholder:hover {
  color: var(--redo-colors-text-text-placeholder);
}

.hover-text-color-brand-primary:hover {
  color: var(--redo-colors-text-text-brand-primary-900);
}

.hover-text-color-brand-secondary:hover {
  color: var(--redo-colors-text-text-brand-secondary-700);
}

.hover-text-color-brand-tertiary:hover {
  color: var(--redo-colors-text-text-brand-tertiary-600);
}

.hover-text-color-error:hover {
  color: var(--redo-colors-text-text-error);
}

.hover-text-color-warning:hover {
  color: var(--redo-colors-text-text-warning-primary-600);
}

.hover-text-color-success:hover {
  color: var(--redo-colors-text-text-success-600);
}

/* Line height */

.line-height-1 {
  line-height: 1;
}

.line-height-1-5 {
  line-height: 1.5;
}

.line-height-2 {
  line-height: 2;
}

/* Fonts */

.font-family-text {
  font-family: Inter;
}

.font-family-header {
  font-family: "Maison Neue Extended", sans-serif;
}

.font-family-inherit {
  font-family: inherit;
}

.font-family-monospace {
  font-family: "Source Code Pro", "Monaco", "Consolas", monospace;
}

/* Alignment */

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.font-style-normal {
  font-style: normal;
}

.font-style-italic {
  font-style: italic;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}
