.outer-container {
  background-color: var(--redo-colors-background-bg-primary);
  border-radius: 10px;
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-secondary);
  display: flex;
  flex-wrap: nowrap;
  gap: var(--redo-spacing);
  padding: 10px 12px;
  row-gap: var(--redo-spacing);
  transition:
    background 250ms,
    border 250ms;
}

.inner-container {
  display: flex;
  flex-grow: 1;
  flex-flow: wrap;
}

.info-tooltip {
  align-self: center;
  display: flex;
}

.outer-container.x-small {
  font-size: var(--redo-body-xsmall-text-size);
}

.outer-container.small {
  font-size: var(--redo-body-small-text-size);
}

.outer-container.medium {
  font-size: var(--redo-body-medium-text-size);
}

.outer-container:hover {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.outer-container:focus-within {
  border-color: var(--redo-primary-color);
  background: white;
}

.outer-container:not(:focus-within).error {
  border: var(--redo-border-width) solid rgb(255, 81, 81);
}

.close {
  --size: 20px;
  color: #444;
  cursor: pointer;
  display: inline-block;
  height: var(--size);
  margin-left: -8px;
  margin-right: 4px;
  padding: 0;
  width: var(--size);
}

.close-icon {
  --size: 20px;
  width: var(--size);
  height: var(--size);
  color: var(
    --redo-component-colors-components-buttons-primary-button-primary-bg
  );
}

.close:hover {
  color: var(
    --redo-component-colors-components-buttons-primary-button-primary-bg_hover
  );
}

.close:active {
  color: var(
    --redo-component-colors-components-buttons-primary-button-primary-bg
  );
}

.chip {
  background-color: var(--redo-colors-background-bg-primary);
  border: var(--redo-border-width) solid
    var(--redo-colors-border-border-primary);
  border-radius: var(--redo-corner-small-radius);
  display: inline-flex;
  padding: 0 16px;
  transition: background-color 250ms;
  white-space: nowrap;
  align-items: center;
}

.chip:focus {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.chip-label {
  display: inline;
}

.input {
  display: inline-block;
  flex: 1;
  width: 180px;
}
