@keyframes fadeIn {
  from {
    opacity: 0;
    bottom: 0px;
  }
  to {
    opacity: 1;
    bottom: 50px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    bottom: 50px;
  }
  to {
    opacity: 0;
    bottom: 100px;
  }
}

.notification-container {
  animation: fadeIn 0.8s ease-in-out;
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 1499;
  &.exit {
    animation: fadeOut 0.8s ease-in-out;
    animation-fill-mode: forwards;
  }
}
