.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--redo-spacing);
}

.centered {
  align-self: center;
  justify-self: center;
}

.centered-flex,
.centered-flex > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-text {
  color: var(--redo-colors-foreground-fg-error-primary);
  outline-color: var(--redo-colors-border-border-error-solid);
}

.icon {
  width: 16px;
  height: 16px;
}

.icon-button-content {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.monospace {
  input {
    font-family: "Source Code Pro", monospace;
  }
}

.padded {
  padding-top: 24px;
  padding-bottom: 16px;
}
