.background {
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.button-primary {
  background: #7380ff;
  border-radius: 2em;
  color: white;
  cursor: pointer;
  font-size: 16px;
  line-height: normal;
  padding: 1em 64px;
  grid-column: span 3;
  transition:
    background-color 0.2s ease,
    scale 0.2s ease;
}

.button-primary:hover {
  background: #4052ff;
}

.button-primary:active {
  background: #2540ff;
  scale: 1.05;
}

.container {
  position: fixed;
  z-index: 5000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redo-modal {
  width: calc(100vw - 60px);
  max-width: 900px;
  min-width: 600px;
  height: 100%;
  background: #f4f4f4;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  align-items: center;
  justify-items: center;
  overflow: auto;
  @media screen and (max-width: 600px) {
    min-width: 0px;
    width: 100vw;
  }
}

.modal-features {
  margin: 0 30px;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.feature {
  text-align: center;
  color: #999;
  padding: 1em;
  flex: 33.3333%;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .feature {
    flex: 100%;
  }
}

.feature-body {
  background-color: #fff;
  padding: 3em;
  height: 100%;
}

.feature-icon {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-icon svg {
  width: 2.5em;
  height: auto;
  transform: scale(1.1);
  display: inline;
}

.feature-title {
  color: #000;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 15px;
  line-height: 1.2em;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  text-transform: unset;
  letter-spacing: initial;
}

.feature-summary {
  font-size: 15px;
  line-height: 1.2em;
}

.modal-footer {
  padding: 0 30px 30px;
  text-align: center;
  font-size: 12px;
  line-height: 1.4em;
  margin: 0 auto;
  max-width: 650px;
}
.modal-footer a {
  color: black;
  text-decoration: none;
}
.modal-footer a:hover {
  color: black;
  text-decoration: underline;
}

.modal-image-logo {
  height: auto;
  width: 8em;
  display: inline-block;
  color: black;
}

.modal-logo {
  text-align: center;
  padding: 2em 25px 0;
}

.modal-close-button {
  position: fixed;
  top: 18px;
  right: 18px;
  cursor: pointer;
}

.modal-logo svg {
  color: black;
  width: 6em;
  display: inline-block;
  height: auto;
  margin-bottom: 10px;
}

.modal-action {
  text-align: center;
  margin: 2em;
}

.modal {
  background: white;
  color: #999;
  box-shadow: 4px 4px 4px 0 rgba(64, 64, 64, 0.2);
  font-size: 14px;
  font-family: Inter, "Helvetica Neue", Arial, sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 60px);
  overflow-y: hidden;
  height: 620px;

  @media screen and (max-width: 766px) {
    height: calc(100vh - 60px);
    max-height: none;
  }
}
