.root {
  z-index: 2147483005 !important; /* 2 higher than the intercom chat bubble */
}

.dropdown {
  background-color: var(--redo-colors-background-bg-primary);
  color: var(--redo-colors-text-text-primary-900);
  box-shadow: 0px 14px 40px rgba(24, 24, 24, 0.09);
  margin: 8px;
  transition:
    opacity 300ms,
    height 300ms,
    transform 300ms;
  transform-origin: top center;
  overflow-y: auto;
  &.constrain-height {
    max-height: 415px;
  }
}

.animating {
  overflow-y: hidden;
}

.dropdown.dark {
  background: var(--redo-component-colors-utility-gray-utility-gray-700);
  border-radius: var(--redo-corner-small-radius);
}

.dropdown.leave {
  opacity: 0;
  transform: scale(0.92);
}

.dropdown.background {
  background-color: var(--redo-colors-background-bg-primary);
}

.option {
  font-size: var(--redo-body-medium-text-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 200ms;
}

.option.dark {
  width: 100%;
}

.option.dark:hover {
  background-color: var(--redo-nav-background-active);
}

:global(.Mui-focusVisible) {
  .option {
    background-color: var(--redo-colors-background-bg-primary_hover) !important;
  }
}

.option:hover {
  background-color: var(--redo-colors-background-bg-primary_hover);
}

.option:disabled {
  text-decoration: line-through;
  color: var(--redo-colors-text-text-disabled);
}
