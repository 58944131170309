.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.image-button-container {
  height: 120px;
  color: var(--redo-colors-text-text-secondary-700);
  outline: 1px solid transparent;
  transition:
    background 250ms,
    color 250ms,
    outline 250ms;

  &:hover {
    background-color: var(--redo-colors-background-bg-tertiary);
  }
}

.hover-filter {
  transition: filter 250ms;
  &:hover {
    filter: contrast(85%);
  }
}

.image-button {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  flex-grow: 1;
  composes: hover-filter;
}

.input {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}

.spinner-container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.spinner {
  height: 24px;
  width: 24px;
}

.video-container {
  composes: hover-filter;
}
