.hierarchy-primary {
  outline: 1px solid;
  outline-offset: -1px;
  box-shadow: 0px 1px 2px 0px #1018280d;

  color: var(
    --redo-component-colors-components-buttons-primary-button-primary-fg
  );

  &.theme-action {
    background-color: var(--redo-visualization-orange-primary);

    &:hover {
      background-color: var(
        --redo-component-colors-utility-orange-dark-utility-orange-dark-400
      );
    }

    &:active,
    &.pressed {
      background-color: var(--redo-visualization-orange-secondary);
    }
  }

  &.theme-normal {
    outline-color: var(
      --redo-component-colors-components-buttons-primary-button-primary-border
    );
    background-color: var(
      --redo-component-colors-components-buttons-primary-button-primary-bg
    );

    &:hover {
      outline-color: var(
        --redo-component-colors-components-buttons-primary-button-primary-border_hover
      );
      background-color: var(
        --redo-component-colors-components-buttons-primary-button-primary-bg_hover
      );
    }

    &:active,
    &.pressed {
      outline-color: var(
        --redo-component-colors-components-buttons-primary-button-primary-border_hover
      );
      background-color: var(
        --redo-component-colors-components-buttons-primary-bg_pressed
      );
    }
  }

  &.theme-destructive {
    outline-color: var(
      --redo-component-colors-components-buttons-primary-error-button-primary-error-border
    );
    background-color: var(
      --redo-component-colors-components-buttons-primary-error-button-primary-error-bg
    );

    &:hover {
      outline-color: var(
        --redo-component-colors-components-buttons-primary-error-button-primary-error-border_hover
      );
      background-color: var(
        --redo-component-colors-components-buttons-primary-error-button-primary-error-bg_hover
      );
    }

    &:active,
    &.pressed {
      outline-color: var(
        --redo-component-colors-components-buttons-primary-error-button-primary-error-border_hover
      );
      background-color: var(
        --redo-component-colors-components-buttons-primary-error-bg_pressed
      );
    }
  }

  &.theme-success {
    outline-color: var(--redo-colors-foreground-fg-success-primary);
    background-color: var(--redo-colors-foreground-fg-success-primary);

    &:hover {
      outline-color: var(
        --redo-component-colors-utility-success-utility-success-700
      );
      background-color: var(
        --redo-component-colors-utility-success-utility-success-700
      );
    }

    &:active,
    &.pressed {
      outline-color: var(
        --redo-component-colors-utility-success-utility-success-700
      );
      background-color: var(
        --redo-component-colors-components-buttons-primary-success-bg_pressed
      );
    }
  }

  &.disabled {
    outline-color: var(--redo-colors-border-border-disabled_subtle);
    background-color: var(--redo-colors-background-bg-disabled);
    color: var(--redo-colors-foreground-fg-disabled);
  }
}

.hierarchy-secondary,
.hierarchy-tertiary,
.hierarchy-link-underline,
.hierarchy-link-gray {
  outline: 1px solid;
  outline-offset: -1px;

  /* Only difference between secondary and tertiary is the presence of
     a border and a secondary cares about its base background */
  &.hierarchy-tertiary {
    outline: 0px;
  }

  /* Only difference between secondary and link is the presence of
     a border and a secondary cares about its base background */
  &.hierarchy-link-gray,
  &.hierarchy-link-underline {
    outline: 0px;
  }

  &.hierarchy-link-underline {
    text-decoration: underline;
  }

  &.hierarchy-secondary {
    box-shadow: 0px 1px 2px 0px #1018280d;
    background-color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-bg
    );
  }

  &.theme-normal {
    color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-fg
    );
    outline-color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-border
    );
    &:hover {
      color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-fg_hover
      );
      outline-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border_hover
      );
      &.hierarchy-secondary,
      &.hierarchy-tertiary {
        background-color: var(--redo-colors-background-bg-primary_hover);
      }
    }
    &.hierarchy-secondary,
    &.hierarchy-tertiary {
      &:active,
      &.pressed {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-bg_pressed
        );
      }
    }
  }

  &.theme-action {
    color: var(--redo-visualization-orange-primary);
    outline-color: var(
      --redo-component-colors-components-buttons-secondary-button-secondary-border
    );
    &:hover {
      color: var(
        --redo-component-colors-utility-orange-dark-utility-orange-dark-400
      );
      outline-color: var(
        --redo-component-colors-components-buttons-secondary-button-secondary-border_hover
      );
      &.hierarchy-secondary,
      &.hierarchy-tertiary {
        background-color: var(--redo-colors-background-bg-primary_hover);
      }
    }
    &.hierarchy-secondary,
    &.hierarchy-tertiary {
      &:active,
      &.pressed {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-bg_pressed
        );
      }
    }
  }

  &.theme-destructive {
    color: var(--redo-colors-text-text-error-primary-600);
    outline-color: var(
      --redo-component-colors-components-buttons-secondary-error-button-secondary-error-border
    );
    &:hover {
      color: var(
        --redo-component-colors-components-buttons-secondary-error-button-secondary-error-fg_hover
      );
      outline-color: var(
        --redo-component-colors-components-buttons-secondary-button-error-border_hover
      );
      &.hierarchy-secondary,
      &.hierarchy-tertiary {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-error-button-secondary-error-bg_hover
        );
      }
    }
    &.hierarchy-secondary,
    &.hierarchy-tertiary {
      &:active,
      &.pressed {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-error-bg_pressed
        );
      }
    }
  }

  &.theme-success {
    outline-color: var(
      --redo-component-colors-utility-success-utility-success-600
    );
    color: var(--redo-colors-text-text-success-primary-600);

    &:hover {
      color: var(--redo-component-colors-utility-green-utility-green-800);
      outline-color: var(
        --redo-component-colors-utility-success-utility-success-300
      );
      &.hierarchy-secondary,
      &.hierarchy-tertiary {
        background-color: var(
          --redo-component-colors-components-buttons-primary-success-bg_hover
        );
      }
    }
    &.hierarchy-secondary,
    &.hierarchy-tertiary {
      &:active,
      &.pressed {
        background-color: var(
          --redo-component-colors-components-buttons-secondary-success-bg_pressed
        );
      }
    }
  }

  &.disabled {
    outline-color: var(--redo-colors-border-border-disabled_subtle);
    color: var(--redo-colors-foreground-fg-disabled);
  }
}
