.lined-container {
  border-bottom: 1px solid var(--redo-colors-border-border-primary);
  &.rely-on-external-bottom-border {
    border-bottom: none;
  }
  white-space: nowrap;
}

.tab {
  &.full-width {
    flex: 1 1 240px;
  }

  border-bottom: 2px solid transparent;

  /** on hover, apply a lighter color border */
  &:hover {
    border-bottom: 2px solid var(--redo-colors-border-border-primary);
  }

  &.selected {
    border-bottom: 2px solid var(--redo-colors-foreground-fg-primary-900);
  }

  /** The very first tab should have no padding on the left */
  &:first-child {
    padding-left: 0;
  }
}
